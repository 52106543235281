import React from "react";
import { Accordion } from 'react-bootstrap';
import { ListCheck, People, ClockHistory, Calendar3, JournalCheck, LayoutWtf, Search, PatchCheck } from 'react-bootstrap-icons';
function DesignAdvisoryAccordion(props) {
    return (

        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header className="bg-primary"><h4><ListCheck className="me-3" />Design advisory</h4></Accordion.Header>
                <Accordion.Body bg="dark" className="bg-primary">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className="bg-primary"><h5><ListCheck className="me-3" />Step 1: Questionnaire</h5></Accordion.Header>
                            <Accordion.Body bg="dark" className="bg-primary">
                                Understanding your environment and criteria/standard for system design.
                            </Accordion.Body>
                        </Accordion.Item>
                        <br />
                        <Accordion.Item eventKey="1">
                            <Accordion.Header className="bg-primary"><h5><LayoutWtf className="me-3" />Step 2: Review Scope</h5></Accordion.Header>
                            <Accordion.Body bg="dark" className="bg-primary">
                                Understanding your implementation approach, familiarization with your current systems(as-is), tools and technologies, and competency of your staff.
                            </Accordion.Body>
                        </Accordion.Item>
                        <br />
                        <Accordion.Item eventKey="2">
                            <Accordion.Header className="bg-primary"><h5><ClockHistory className="me-3" />Step 3: Estimation</h5></Accordion.Header>
                            <Accordion.Body bg="dark" className="bg-primary">
                                We'll provide you a detailed estimation for designing your systems.
                            </Accordion.Body>
                        </Accordion.Item>
                        <br />
                        <Accordion.Item eventKey="3">
                            <Accordion.Header className="bg-primary"><h5><People className="me-3" />Step 4: Planning & selection</h5></Accordion.Header>
                            <Accordion.Body bg="dark" className="bg-primary">
                                Identify competent advisors based on standard/criteria, training and on-boarding of advisors, familiarization with your current systems, tools, and technologies. This is followed by an approval from your team.
                            </Accordion.Body>
                        </Accordion.Item>
                        <br />
                        <Accordion.Item eventKey="4">
                            <Accordion.Header className="bg-primary"><h5><Search className="me-3" />Step 5: System design</h5></Accordion.Header>
                            <Accordion.Body bg="dark" className="bg-primary">
                                The advisor will carry out the design of your <br /><br />
                                ● System components<br />
                                ● Document policies and procedures<br />
                                ● Carry out risk assessments<br />
                                ● Plan risk treatment<br />
                                ● Design controls as required<br />
                                ● Design monitoring & measurement systems<br />
                                ● Train your staff<br />
                                ● Help in internal assessments
                            </Accordion.Body>
                        </Accordion.Item>
                        <br />
                        <Accordion.Item eventKey="5">
                            <Accordion.Header className="bg-primary"><h5><JournalCheck className="me-3" />Step 6: Weekly reporting & review</h5></Accordion.Header>
                            <Accordion.Body bg="dark" className="bg-primary">
                                Weekly review of artifacts, approval of components which has been designed, and system change requirements.
                            </Accordion.Body>
                        </Accordion.Item>
                        <br />
                        <Accordion.Item eventKey="6">
                            <Accordion.Header className="bg-primary"><h5><Calendar3 className="me-3" />Step 7: Monthly performance review</h5></Accordion.Header>
                            <Accordion.Body bg="dark" className="bg-primary">
                                Each month we'll perform a status review so that we are in alignment with your standards and requirements, work on improvement plans.
                            </Accordion.Body>
                        </Accordion.Item>
                        <br />
                    </Accordion>
                </Accordion.Body>
            </Accordion.Item>
            <br></br>
            <Accordion.Item eventKey="1">
                <Accordion.Header className="bg-primary"><h4><ListCheck className="me-3" />Support services</h4></Accordion.Header>
                <Accordion.Body bg="dark" className="bg-primary">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className="bg-primary"><h5><ListCheck className="me-3" />Step 1: Questionnaire</h5></Accordion.Header>
                            <Accordion.Body bg="dark" className="bg-primary">
                                Understanding your environment and criteria/standard for system design.
                            </Accordion.Body>
                        </Accordion.Item>
                        <br />
                        <Accordion.Item eventKey="1">
                            <Accordion.Header className="bg-primary"><h5><LayoutWtf className="me-3" />Step 2: Review Scope</h5></Accordion.Header>
                            <Accordion.Body bg="dark" className="bg-primary">
                                Understanding your implementation approach, familiarization with your current systems(as-is), tools and technologies, and competency of your staff.
                            </Accordion.Body>
                        </Accordion.Item>
                        <br />
                        <Accordion.Item eventKey="2">
                            <Accordion.Header className="bg-primary"><h5><ClockHistory className="me-3" />Step 3: Estimation</h5></Accordion.Header>
                            <Accordion.Body bg="dark" className="bg-primary">
                                We'll provide you a detailed estimation for supporting your systems and changes etc.
                            </Accordion.Body>
                        </Accordion.Item>
                        <br />
                        <Accordion.Item eventKey="3">
                            <Accordion.Header className="bg-primary"><h5><People className="me-3" />Step 4: Planning & selection</h5></Accordion.Header>
                            <Accordion.Body bg="dark" className="bg-primary">
                                Identify competent support advisors based on standard/criteria, training and on-boarding of support advisors, familiarization with your current systems, tools, and technologies. This is followed by an approval from your team.
                            </Accordion.Body>
                        </Accordion.Item>
                        <br />
                        <Accordion.Item eventKey="4">
                            <Accordion.Header className="bg-primary"><h5><Search className="me-3" />Step 5: System support</h5></Accordion.Header>
                            <Accordion.Body bg="dark" className="bg-primary">
                                The advisor will plan and execute the support lifecycle to maintain and upgrade your internal systems<br /><br />
                                ● Revisit system components<br />
                                ● Revisit and review policies and procedures<br />
                                ● Review and upgrade risk assessments<br />
                                ● Propose any required risk treatment<br />
                                ● Add/upgrade controls as required<br />
                                ● Review monitoring & measurement systems<br />
                                ● Train your staff<br />
                                ● Help in internal assessments
                            </Accordion.Body>
                        </Accordion.Item>
                        <br />
                        <Accordion.Item eventKey="5">
                            <Accordion.Header className="bg-primary"><h5><JournalCheck className="me-3" />Step 6: Weekly reporting & review</h5></Accordion.Header>
                            <Accordion.Body bg="dark" className="bg-primary">
                                Weekly review of artifacts, approval of components which has been designed/upgraded, and system changes.
                            </Accordion.Body>
                        </Accordion.Item>
                        <br />
                        <Accordion.Item eventKey="6">
                            <Accordion.Header className="bg-primary"><h5><Calendar3 className="me-3" />Step 7: Monthly performance review</h5></Accordion.Header>
                            <Accordion.Body bg="dark" className="bg-primary">
                                Each month we'll perform a status review so that we are in alignment with your standards and requirements, work on improvement plans.
                            </Accordion.Body>
                        </Accordion.Item>
                        <br />
                    </Accordion>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default DesignAdvisoryAccordion;  