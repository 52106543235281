import React from "react";
import {
    Link
} from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import AnimatedPages from "./AnimatedPages";

function Home(props) {
    return <AnimatedPages><header class="bg-primary py-5">
        <div class="container px-5  pt-5 pb-5" id="home">
            <div class="row gx-5 justify-content-center">
                <div class="col-lg-6">
                    <div class="text-left my-5 pt-5 pb-5">
                        {/* <h1 class="lead text-white mb-2"><code className="text-white">{props.pageElements.location}</code></h1> */}
                        <p class="display-5 text-white-50 mb-4"><ReactMarkdown>{props.pageElements.title}</ReactMarkdown></p>
                        <div class="d-grid gap-3 d-sm-flex justify-content-sm-left">

                        </div>
                    </div>
                </div>
                <div class="col-lg-6 my-5 pt-5 pb-5">
                    <div class="text-left">
                        {/* <!-- <h1 class="display-5 fw-bolder text-white mb-2">We are an established finance company that provides acquisition, development and finance expertise for both commercial and multi-family projects.</h1> --> */}
                        <p class="lead text-success mb-4">{props.pageElements.subTitle}</p>
                        <div class="d-grid gap-3 d-sm-flex justify-content-sm-left pt-5 pb-5">
                            <Link class="btn btn-outline-success btn-lg px-4 me-sm-3" to="/get-started">{props.pageElements.buttonOne}</Link>
                            <Link class="btn btn-outline-success btn-lg px-4 me-sm-3" to="/about">{props.pageElements.buttonTwo}</Link>
                            {/* <!-- <a class="btn btn-outline-light btn-lg px-4" href="#!">Learn More</a> --> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header></AnimatedPages>;
}

export default Home;  