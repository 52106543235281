import React from "react";
import { Building, People, ClockHistory, Calendar3, Lock, BookHalf, Boxes } from 'react-bootstrap-icons';

import ServiceSummary from '../../components/ServiceSummary'
import StatsCard from '../../components/StatsCard'
import ServiceButtons from '../../components/ServiceButtons'
import Lottie from "lottie-react";
import icon_people from "../../resources/lotties/icon_people.json";
import icon_people_1 from "../../resources/lotties/icon_people_1.json";
import icon_write from "../../resources/lotties/icon_write.json";
import icon_menu from "../../resources/lotties/icon_menu.json";

const about = [
    {
        title: "Compliance - made easy.",
        description: "Our experienced security assessors can perform an independent, detailed adequacy and compliance pre certification audits and also support your internal audit process based on various global compliance criteria.",
        link: "audit",
        serviceSummaryArray: [
            <ServiceSummary title="Experienced & approachable audit, advisory, & support team" description="Our partners have over 15+ years of experience as a full-service provider, serving clients in numerous industries around the globe. Please feel free to reach out to us with any questions at any point in the process." />,
            <ServiceSummary title="Based out of Dallas, TX" description="We're based in the United States, with an office in Dallas, TX. This allows you to work locally, at the same time gain the international advantage with cost and resource optimization." />,
            <ServiceSummary title="Transparent pricing" description="Upon completing our questionnaire, we will provide you with an accurate/affordable price quote. Our goal is to provide clarity and avoid surprises." />,
            <ServiceSummary title="Transparent communication" description="We always put your needs first when we work together, which is why we answer our phones during normal business hours and respond to emails the same day." />,
        ], statsArray: [
            <StatsCard icon={<Lottie style={{ height: '3em', textAlign: 'center' }} loop={true} animationData={icon_people} />} heading="100+" subtitle="auditors globally" />,
            <StatsCard icon={<Lottie style={{ height: '3em', textAlign: 'center' }} loop={true} animationData={icon_people_1} />} heading="50+" subtitle="advisors globally" />,
            <StatsCard icon={<Lottie style={{ height: '3em', textAlign: 'center' }} loop={true} animationData={icon_write} />} heading="5000+" subtitle="resources trained globally" />,
            <StatsCard icon={<Lottie style={{ height: '3em', textAlign: 'center' }} loop={true} animationData={icon_menu} />} heading="20+ y" subtitle="domain experience" />,
        ],
        serviceButtons: <ServiceButtons />
    }
];

export default about;

