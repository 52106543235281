import React from "react";
import AnimatedPages from "../pages/AnimatedPages";
const Field = ({ field, fieldChanged, type, value, fieldValidate }) => {
  return (
    <AnimatedPages>
      <div className={field.colSize != null && field.colSize ? field.colSize : "col-lg-12"}>
        <div className="form-floating mb-3" key={field._uid}>
          {field.label.length > 20 ? <p className="text-white-50">{field.label}</p> : ""}
          <input className="form-control"
            placeholder={field.label.length <= 20 ? field.label : ""}
            type={type || field.component}
            id={field._uid}
            //pattern={field.validationParams != null && field.validationParams.pattern != null ? field.validationParams.pattern : (field.validationParams != null && field.validationParams.regexp != null ? field.validationParams.regexp : "^[a-zA-Z0-9_.-]*$")}
            name={field._uid}
            value={value}
            onChange={(e) => {
              // Notify the main state list of the new value
              fieldChanged(field._uid, e.target.value, field.validationParams);
            }}
            onBlur={(e) => {
              // Notify the main state list of the new value
              fieldValidate(field._uid, e.target.value, field.validationParams);
            }}
          />
          {field.label.length <= 20 ? <label htmlFor={field._uid}>{field.label}</label> : ""}
        </div>
      </div>
    </AnimatedPages>
  );
};

export default Field;
