import React from "react";
import {
    Link
} from "react-router-dom";
import uuid from 'react-uuid'
import ServiceSummaryLink from '../components/ServiceSummaryLink'


import AnimatedPages from "./AnimatedPages";
function Services(props) {
    var serviceArray = [];
    for (var i = 0; i < 3; i++) {
        serviceArray.push(props.pageElements[i])
    }
    return <section key={uuid()} class="bg-primary py-5" id="services">
        <div data-aos="fade-up" className="" id="services_section">
        <div class="container px-5 my-5">
                <div class="row gx-5">
                    <div class="col-lg-12 mb-5 mb-lg-0">
                        <h2 class="display-5 text-white-50 mb-4">Services.</h2>
                    </div>

                </div>
            </div>
            {serviceArray.map(item => {
                return (
                    <>
                        <ServiceSummaryLink key={uuid()} title={`${item.title}`} description={`${item.description}`} link={`${item.link}`} />
                    </>
                )
            })}

            <div class="container px-5 my-5">
                <div class="row gx-5">
                    <div class="col-lg-3 mb-5 mb-lg-0">
                        <br></br><br></br>
                        {/* <Link class="btn btn-outline-light btn-lg px-4" to="/services/all-services">More services</Link> */}
                    </div>
                    <div class="col-lg-5 mb-5 mb-lg-0">
                    </div>
                    <div class="col-lg-4  mb-5 mb-lg-0">
                    </div>
                </div>
            </div>
        </div>
    </section>;
}

export default Services;  