import React, { Fragment, useState } from "react";
import ReCaptchaV2 from 'react-google-recaptcha'

const ReCaptchaField = ({ field, fieldChanged, value, fieldGrpId }) => {
    const [recaptchaToken, setRecaptchaToken] = useState("");
    const recaptchaSiteKey = "6Ld9DGgeAAAAAGTuKb_q96N1jiaii-6_oQJluNOl";

    const handleToken = (token) => {
        //console.log(token);
        fieldChanged(field._uid, token, null);
        setRecaptchaToken(token);
    }

    const handleExpire = () => {
        //console.log("Token expired");
        fieldChanged(field._uid, "", null);
        setRecaptchaToken("");
    }

    return (
        <div id={"reCaptchaField"} className={field.colSize != null && field.colSize ? field.colSize : "col-lg-12"}>
            <div class="">
                <ReCaptchaV2
                    sitekey={recaptchaSiteKey}
                    onChange={handleToken}
                    onExpire={handleExpire} />
            </div>

        </div>

    );
};

export default ReCaptchaField;
