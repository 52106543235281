import React from "react";
import {
    Link
} from "react-router-dom";
import TitleCard from "../dynamicForms/TitleCard";
import Lottie from "lottie-react";
import yellowCheck from "../resources/lotties/yellow_check-2.json";

function Footer(props) {
    return <header class="bg-primary py-5">
        <div class="container px-5  pt-5 pb-5" id="home">
            <div class="row gx-5 justify-content-center">
                <TitleCard title={props.data.title} subTitle={props.data.subTitle} animation={props.data.animation}  hideButton={props.data.hideButton}></TitleCard>
            </div>
        </div>
    </header>
}

export default Footer;  