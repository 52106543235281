import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Link,
} from "react-router-dom";

import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import ScrollToTop from './ScrollToTop.js'
import RoutesWrapper from './RoutesWrapper.js'
import Footer from './Footer.js'
import { AnimatePresence } from "framer-motion";
import AOS from 'aos';
import 'aos/dist/aos.css';
import logo from "../resources/images/Logo_small.png";
function Header(props) {

    useEffect(()=>{
        AOS.init({duration: 1000});
    }, [])

    function linkClick(){
        if (document.getElementById("basic-navbar-nav") != undefined) {
            document.getElementById("basic-navbar-nav").classList.remove('show');
        }
    }
    // const location=useLocation();
    return (<Router><div id="topheader">
        <Navbar bg="primary" className="navbar-dark" expand="lg">
            <Container className="px-5">
                <Navbar.Brand className="fw-bolder" href="/"><Link class="navbar-header fw-bolder" to="/"><img src={logo} style={{ height: '40%', width: '40%', textAlign: 'center' }}></img></Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <Link onClick={linkClick} class="nav-link" to="/home">Home</Link>
                        </li>
                        <li class="nav-item">
                            <Link onClick={linkClick}  class="nav-link" to="/services">Services</Link>
                        </li>
                        {/* <Link class="nav-link" to="/services">Shop</Link> */}
                        <li class="nav-item">
                            <Link onClick={linkClick}  class="nav-link" to="/about">About</Link>
                        </li>
                        <li class="nav-item">
                            <Link onClick={linkClick}  class="nav-link" to="/contact">Contact</Link>
                        </li>
                        {/* <NavDropdown  className="bg-primary navbar-dark" title="Dropdown" id="basic-nav-dropdown">
                            <NavDropdown.Item className="bg-primary" href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item  className="bg-primary"href="#action/3.2">Another action</NavDropdown.Item>
                            <NavDropdown.Item  className="bg-primary" href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider className="bg-primary" />
                            <NavDropdown.Item className="bg-primary" href="#action/3.4">Separated link</NavDropdown.Item>
                        </NavDropdown> */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </div>

        <ScrollToTop />
        <AnimatePresence exitBeforeEnter>
            <RoutesWrapper data={props.data} />
        </AnimatePresence>
        <Footer data={props.data} />
    </Router>)

}

export default Header;  