import './App.css';

import Header from './pages/Header.js'
import websiteData from './pages/data/Website-Data.js'

function App() {
  return (
    <body style={{ "background": "#172179" }}>
      <Header data={websiteData} />
    </body>
  );
}

export default App;
