import React from "react";
import {
    Link
} from "react-router-dom";
import AnimatedPages from "./AnimatedPages";
import Home from './Home.js'
import NotFound from './NotFound.js'
import Services from './Services.js'
import About from './About.js'
import Contact from './Contact.js'
import Privacy from './Privacy.js'
import Questionnaire from './Questionnaire'
import AllServicesSummary from './services/All-Services-Summary.js'
import Service from '../components/Service'
import { useLocation } from 'react-router-dom'

function AllPages(props) {

    return <AnimatedPages>
        <Home pageElements={props.data.pageData.home}></Home>
        <hr className="text-white-50" />
        <Services pageElements={props.data.pageData.services}></Services>
        <hr className="text-white-50" />
        <About pageElements={props.data.pageData.about} hideButtons={true} />
        <hr className="text-white-50" />
        <Contact data={props.data} />
    </AnimatedPages>;
}

export default AllPages;  