import React from "react";
import AnimatedPages from "../pages/AnimatedPages";
import { ProgressBar } from 'react-bootstrap';

function PageNav(props) {
    return (<div>
        {props.page > 0 && props.page < props.formData.length - 1 && (
            <AnimatedPages>
                <div className="col-lg-8">
                    <div class="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3"><ProgressBar striped animated variant="info" now={(props.page / (props.formData.length - 2)) * 100} /></div>
                    </div><br /></div>
                <br></br>
                {props.page > 0 && props.page < props.formData.length - 1 && <button class="btn btn-outline-info btn-md px-4 me-sm-3" onClick={props.prevPage}>Back</button>}&nbsp;
                {props.page > 0 && props.page < props.formData.length - 2 && <button id="qNext" disabled={false} class="btn btn-outline-success btn-md px-4 me-sm-3" onClick={props.nextPage}>Next</button>}
                {props.page == props.formData.length - 2 && <button class="btn btn-outline-success btn-md px-3 me-sm-3" onClick={props.nextPage}>Submit</button>}
            </AnimatedPages>)}

    </div>);
}

export default PageNav;  