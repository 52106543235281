import React from "react";
import {
    Link
} from "react-router-dom";


function Footer(props) {
    return <footer class="py-5 bg-dark">
        <div class="container px-5"><p class="m-0 text-center text-white"><Link class="text-white" to="/privacy">Our Privacy Policy.</Link><span class="mx-2"></span> Copyright &copy; {props.data.companyFullName} 2021</p></div>
    </footer>
}

export default Footer;  