import React, { useState, useEffect } from "react";
import FieldGroup from "./FieldGroup";
import Field from "./Field";
import TextArea from "./TextArea";
import Option from "./Option";
import Select from "./Select";
import TitleCard from "./TitleCard";
import ReCaptchaField from "./ReCaptchaField";
import PageNav from "./PageNav";
import Checkbox from "./Checkbox";
import uuid from "react-uuid";
// import { PrintDict } from "../pages/helpers/PrintDict";
// import { ConstructMsg } from "../pages/helpers/ConstructMsg";

const fieldMeetsCondition = (values) => (field) => {
    if (field.conditional && field.conditional.field) {
        const segments = field.conditional.field.split("_");
        const fieldId = segments[segments.length - 1];
        //console.log("FieldID:" + fieldId+"  Segments:" + field.conditional.field)
        if (fieldId.includes("$")) {
            //multi field
            const fields = fieldId.split("$");
            fields.forEach((x, i) => {
                if (values[x] === field.conditional.value) {
                    return true;
                }
            }
            );

        } else {
            //console.log(fieldId + " " + field.conditional.value)
            // console.log(values[fieldId] === field.conditional.value)
            return values[fieldId] === field.conditional.value;
        }
    }
    return true;
};

const Form = ({ formData }) => {
    // state to track the current page ID of the form
    const [page, setPage] = useState(0);

    // state to track the current form data that will be displayed
    const [currentPageData, setCurrentPageData] = useState(formData[page]);

    // track the values of the form fields
    const [values, setValues] = useState({});

    const alertRefreshUser = (e) => {
        e.preventDefault();
        e.returnValue = "";
      };

    // this effect will run when the `page` changes
    useEffect(() => {
        const upcomingPageData = formData[page];
        setCurrentPageData(upcomingPageData);
        setValues((currentValues) => {
            const newValues = upcomingPageData.fields.reduce((obj, field) => {
                if (field.component === "field_group") {
                    for (const subField of field.fields) {
                        obj[subField._uid] = "";
                    }
                } else {
                    obj[field._uid] = "";
                }

                return obj;
            }, {});

            return Object.assign({}, newValues, currentValues);
        });
        //Scroll to top
        window.scrollTo(0, 0);

        window.addEventListener("beforeunload", alertRefreshUser);
        return () => {
          window.removeEventListener("beforeunload", alertRefreshUser);
        };

    }, [page, formData]);

    // callback provided to components to update the main list of form values
    const fieldChanged = (fieldId, value, validationParams) => {

        if (document.getElementById("qNext") != undefined) {
            document.getElementById("qNext").disabled = false;
        }
        if (document.getElementById("errBlock") != undefined) {
            document.getElementById("errBlock").hidden = true;
        }
        var paramVal = value;
        //To handle Checkbox case
        if (value.checked != undefined) {

            if (value.checked) {
                value = value.value;
                if ((fieldId.includes(".none") || fieldId.includes(".training")) && value == "Y") {
                    if (document.getElementById(paramVal.fieldGrpId) != undefined) {
                        var input = document.getElementById(paramVal.fieldGrpId).getElementsByTagName("input");

                        for (var i = 0; i < input.length; i++) {
                            if (input[i].id != fieldId) {
                                var checkBox = document.getElementById(input[i].id);
                                if (checkBox.checked) {
                                    checkBox.click();
                                }
                                checkBox.disabled = true;
                            }
                        }
                    }
                }

            } else {
                value = "";
                if (fieldId.includes(".none") || fieldId.includes(".training")) {
                    if (document.getElementById(paramVal.fieldGrpId) != undefined) {
                        var input = document.getElementById(paramVal.fieldGrpId).getElementsByTagName("input");

                        for (var i = 0; i < input.length; i++) {
                            if (input[i].id != fieldId) {
                                var checkBox = document.getElementById(input[i].id);
                                checkBox.disabled = false;
                            }
                        }
                    }
                }
            }
        }

        // use a callback to find the field in the value list and update it
        setValues((currentValues) => {
            currentValues[fieldId] = value;
            return currentValues;
        });

        // this just fakes that we've updated the `currentPageData` to force a re-render in React
        setCurrentPageData((currentPageData) => {
            return Object.assign({}, currentPageData);
        });

    };

    // callback provided to components to update the main list of form values
    const fieldValidate = (fieldId, value, validationParams) => {
        // console.log(value);
        if (validationParams != null) {
            if (validationParams.req) {
                if (value.length <= 0) {
                    //document.getElementById(fieldId).setCustomValidity(document.getElementById(fieldId).placeholder + " is required")
                    //document.getElementById(fieldId).reportValidity();
                    document.getElementById(fieldId).style.borderBottom = '4px solid #FF304F';
                    document.getElementById(fieldId).style.background = '#FFFFFF';

                } else {
                    var re = new RegExp(validationParams.regexp);
                    if (re.test(value)) {
                        //document.getElementById(fieldId).setCustomValidity('');
                        document.getElementById(fieldId).style.borderBottom = '4px solid #00bd9d';
                        document.getElementById(fieldId).style.background = '#FFFFFF';
                        document.getElementById("qNext").disabled = false;

                    } else {
                        //document.getElementById(fieldId).setCustomValidity(validationParams.errMsg)
                        //document.getElementById(fieldId).reportValidity();
                        document.getElementById(fieldId).style.borderBottom = '4px solid #FF304F';
                        document.getElementById(fieldId).style.background = '#FFFFFF';
                        //Focus back
                        //setTimeout(function(){document.getElementById(fieldId).focus()}, 10);
                        //document.getElementById("qNext").disabled = true;
                        // document.getElementById("qNext").classList.add('btn-danger');
                    }
                }

            }
        }

    };

    const navigatePages = (direction) => () => {
        const findNextPage = (page) => {
            var bErr = [];
            const bErrX = currentPageData.fields
                .filter(fieldMeetsCondition(values))
                .map((field) => {
                    switch (field.component) {
                        case "field_group":
                            var grpValidated = false;
                            var checkboxFound = false;
                            //iterate through the list of fields of the field_group
                            field.fields.forEach((x, i) => {
                                if (x.validationParams != null) {
                                    var valParams = x.validationParams;
                                    //required
                                    var bRequiredValidated = false;
                                    if (valParams.req) {
                                        bRequiredValidated = true;
                                        if (values[x._uid].length <= 0) {
                                            var errMsg = x.name != undefined && x.name.length > 0 ? x.name : x.label;
                                            bErr.push(errMsg + " is required");

                                            document.getElementById(x._uid).style.borderBottom = '4px solid #FF304F';
                                            document.getElementById(x._uid).style.background = '#FFFFFF';
                                        }
                                        else //Check regexp now
                                        {
                                            var re = new RegExp(valParams.regexp);
                                            if (!re.test(values[x._uid])) {
                                                bErr.push(valParams.errMsg);

                                                document.getElementById(x._uid).style.borderBottom = '4px solid #FF304F';
                                                document.getElementById(x._uid).style.background = '#FFFFFF';
                                            }
                                        }
                                    }
                                    if (!bRequiredValidated && valParams.regexp.length > 0)//Not required, but needs validation
                                    {
                                        var re = new RegExp(valParams.regexp);
                                        if (!re.test(values[x._uid])) {
                                            bErr.push(valParams.errMsg);

                                            document.getElementById(x._uid).style.borderBottom = '4px solid #FF304F';
                                            document.getElementById(x._uid).style.background = '#FFFFFF';
                                            //document.getElementById(x._uid).classList.add("is-invalid")
                                        }
                                    }
                                }

                                //Group validation
                                if (field.validationParams != undefined) {
                                    if (x.component = "checkbox") {
                                        checkboxFound = true;
                                        if (document.getElementById(x._uid).checked) {//if any one is selected, we are ok
                                            grpValidated = true;
                                        }
                                    }
                                }
                            });

                            //Field group level error message
                            if (field.validationParams != undefined && field.validationParams.req && checkboxFound && !grpValidated) {
                                bErr.push(field.validationParams.errMsg);
                            }
                            break;
                        default:
                            if (field.validationParams != undefined) {
                                var valParams = field.validationParams;
                                //required
                                var bRequiredValidated = false;
                                if (valParams.req) {
                                    bRequiredValidated = true;
                                    if (values[field._uid].length <= 0) {
                                        var errMsg = field.name != undefined && field.name.length > 0 ? field.name : field.label;
                                        bErr.push(errMsg + " is required");

                                        document.getElementById(field._uid).style.borderBottom = '4px solid #FF304F';
                                        document.getElementById(field._uid).style.background = '#FFFFFF';
                                    }
                                    else //Check regexp now
                                    {
                                        var re = new RegExp(valParams.regexp);
                                        if (!re.test(values[field._uid])) {
                                            bErr.push(valParams.errMsg);

                                            document.getElementById(field._uid).style.borderBottom = '4px solid #FF304F';
                                            document.getElementById(field._uid).style.background = '#FFFFFF';
                                        }
                                    }
                                }
                                if (!bRequiredValidated && valParams.regexp.length > 0)//Not required, but needs validation
                                {
                                    var re = new RegExp(valParams.regexp);
                                    if (!re.test(values[field._uid])) {
                                        bErr.push(valParams.errMsg);

                                        document.getElementById(field._uid).style.borderBottom = '4px solid #FF304F';
                                        document.getElementById(field._uid).style.background = '#FFFFFF';
                                    }
                                }
                            }
                            break;
                    }
                    return bErr;
                });
            if (direction === "next" && bErr.length > 0) {
                if (document.getElementById("errBlock") != undefined) {

                    var errMsgs = "";
                    bErr.forEach((x, i) => {
                        errMsgs += "● " + x + "\r\n";
                    }
                    );
                    document.getElementById("errBlockMessages").innerText = errMsgs;
                    document.getElementById("errBlock").hidden = false;

                }
                if (document.getElementById("qNext") != undefined) {
                    document.getElementById("qNext").disabled = true;
                }
                return page - 1;
            } else {
                if (document.getElementById("qNext") != undefined) {
                    document.getElementById("qNext").disabled = false;
                }

                if (document.getElementById("errBlock") != undefined) {
                    document.getElementById("errBlock").hidden = true;

                }
            }
            const upcomingPageData = formData[page];
            if (upcomingPageData.conditional && upcomingPageData.conditional.field) {
                // we're going to a conditional page, make sure it's the right one
                const segments = upcomingPageData.conditional.field.split("_");
                const fieldId = segments[segments.length - 1];

                if (fieldId.includes("~")) {
                    const ignoreSegments = fieldId.split("~");
                    const igFieldId = ignoreSegments[ignoreSegments.length - 1];
                    //Ignore case
                    if (igFieldId.includes("$")) {
                        //multi field
                        const fields = fieldId.split("$");
                        var unmatchedArray = [];
                        fields.forEach((x, i) => {
                            if (values[x] === upcomingPageData.conditional.value) {
                                return findNextPage(direction === "next" ? page + 1 : page - 1);
                            }
                        });
                    } else {
                        //console.log(fieldId + ": " + values[igFieldId])
                        const fieldToMatchValue = values[igFieldId];
                        if (fieldToMatchValue === upcomingPageData.conditional.value) {
                            // if we didn't find a match, try the next page
                            return findNextPage(direction === "next" ? page + 1 : page - 1);
                        }
                    }
                    //Non ignore case
                    if ([ignoreSegments.length - 2] != undefined) {
                        const fieldId = ignoreSegments[ignoreSegments.length - 2];
                        if (fieldId.includes("$")) {
                            //multi field
                            const fields = fieldId.split("$");
                            var unmatchedArray = [];
                            fields.forEach((x, i) => {
                                if (values[x] !== upcomingPageData.conditional.value) {
                                    unmatchedArray.push(values[x]);
                                }
                            });

                            if (unmatchedArray.length === fields.length) {
                                return findNextPage(direction === "next" ? page + 1 : page - 1);
                            }

                        } else {
                            const fieldToMatchValue = values[fieldId];
                            if (fieldToMatchValue !== upcomingPageData.conditional.value) {
                                // if we didn't find a match, try the next page
                                return findNextPage(direction === "next" ? page + 1 : page - 1);
                            }
                        }
                    }

                }
                else {
                    if (fieldId.includes("$")) {
                        //multi field
                        const fields = fieldId.split("$");
                        var unmatchedArray = [];
                        fields.forEach((x, i) => {
                            if (values[x] !== upcomingPageData.conditional.value) {
                                unmatchedArray.push(values[x]);
                            }
                        });

                        if (unmatchedArray.length === fields.length) {
                            return findNextPage(direction === "next" ? page + 1 : page - 1);
                        }

                    } else {
                        const fieldToMatchValue = values[fieldId];
                        if (fieldToMatchValue !== upcomingPageData.conditional.value) {
                            // if we didn't find a match, try the next page
                            return findNextPage(direction === "next" ? page + 1 : page - 1);
                        }
                    }
                }

            }
            // all tests for the page we want to go to pass, so go to it
            return page;
        };
        if (direction === "next" && page == formData.length - 2) {
            // This is the last page, so post data here
            //console.log(values);
            var recaptchaTokenVal = values["lastpage.reCaptcha"];
            values["lastpage.reCaptcha"] = "";
            //console.log(ConstructMsg("XXX", values));
            //Post Data
            const requestOptions = {
                method: 'POST',
                //mode: 'same-origin', // no-cors, *cors, same-origin
                // redirect: 'follow',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({
                    "data": values,
                    "mode": "questionnaire",
                    "recaptchaToken": recaptchaTokenVal
                })
            };
            fetch('/checkRecaptcha', requestOptions)
                .then(response => {
                    if (response.status == 204) {
                        console.log(response.status);
                        setPage(findNextPage(direction === "next" ? page + 1 : page - 1));
                    } else {
                        if (response.status == 401) {
                            console.error("Message could not be sent: re-captcha failed");
                        }
                        else if (response.status == 500) {
                            console.error("Message could not be sent: server function failed");
                        } else {
                            console.error("Message could not be sent: unknown error");
                        }
                    }
                })
                .catch(function (error) {
                    console.error("Message could not be sent: ", error);
                });
        }

        setPage(findNextPage(direction === "next" ? page + 1 : page - 1));
    };

    const nextPage = navigatePages("next");
    const prevPage = navigatePages("prev");
    const submitAndRedirect = function (values) {
        // console.log(values);
        navigatePages("next");
    }

    const onSubmit = (e) => {
        e.preventDefault();
        // todo - send data somewhere
    };

    return (<form onSubmit={onSubmit} className="">
        <h3 class="text-white-50 pb-5">{currentPageData.label}</h3>
        <div id="errBlock" data-aos="fade-up" className="col-lg-8" hidden={true}>

            <div class="alert alert-info" role="alert">
                {/* <h4 class="alert-heading text-primary">Found a few issues!</h4> */}
                <p class="text-primary">Please check the information you've entered before proceeding to the next step.</p>
                <hr />
                <code><p id="errBlockMessages" class="mb-0"></p></code>
            </div>
            <br></br>
        </div>
        {currentPageData.fields
            .filter(fieldMeetsCondition(values))
            .map((field) => {
                switch (field.component) {
                    case "field_group":
                        return (
                            <FieldGroup
                                key={field._uid}
                                field={field}
                                fieldChanged={fieldChanged}
                                // should probably only slice out the required values, but ¯\_(ツ)_/¯
                                values={values}
                                fieldValidate={fieldValidate}
                                page={page}
                                formData={formData}
                                prevPage={prevPage}
                                nextPage={nextPage}
                            />
                        );
                    case "textArea":
                        return (
                            <TextArea
                                key={field._uid}
                                field={field}
                                fieldChanged={fieldChanged}
                                value={values[field._uid]}
                                fieldValidate={fieldValidate}
                            />
                        );
                    case "options":
                        return (
                            <Option
                                key={field._uid}
                                field={field}
                                fieldChanged={fieldChanged}
                                value={values[field._uid]}
                                fieldValidate={fieldValidate}
                            />
                        );
                    case "checkbox":
                        return (
                            <Checkbox
                                key={field._uid}
                                field={field}
                                fieldChanged={fieldChanged}
                                value={values[field._uid]}
                                fieldValidate={fieldValidate}
                            />
                        );
                    case "dropdowns":
                        return (
                            <Select
                                key={field._uid}
                                field={field}
                                fieldChanged={fieldChanged}
                                value={values[field._uid]}
                                fieldValidate={fieldValidate}
                            />
                        );
                    case "titleCard":
                        return (
                            <TitleCard
                                title={field.title}
                                subTitle={field.subTitle}
                                animation={field.animation}
                                hideButton={field.hideButton}
                                key={field._uid}
                                field={field}
                                fieldChanged={fieldChanged}
                                value={values[field._uid]}
                                nextPage={nextPage}
                            />
                        );
                    case "pageNav":
                        return (
                            <PageNav
                                key={field._uid}
                                page={page}
                                formData={formData}
                                prevPage={prevPage}
                                nextPage={nextPage}
                            />
                        );
                    case "reCaptcha":
                        return (
                            <ReCaptchaField
                                key={field._uid}
                                field={field}
                                page={page}
                                fieldChanged={fieldChanged}
                                formData={formData}
                            />
                        );
                    default:
                        return (
                            <Field
                                key={field._uid}
                                field={field}
                                fieldChanged={fieldChanged}
                                value={values[field._uid]}
                                fieldValidate={fieldValidate}
                            />
                        );
                }
            })}
        {/* {page > 0 && page < formData.length - 1 && (<div className="col-lg-8">
                <div class="row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-3"></div>
                    <div className="col-lg-3"></div>
                    <div className="col-lg-3"><ProgressBar striped animated variant="info" now={(page / (formData.length - 2)) * 100} /></div>
                </div><br /></div>)}
            <br></br>
            {page > 0 && page < formData.length - 1 && <button class="btn btn-dark btn-lg px-4 me-sm-3" onClick={prevPage}>Back</button>}&nbsp;
            {page > 0 && page < formData.length - 2 && <button id="qNext" disabled={false} class="btn btn-dark btn-lg px-4 me-sm-3" onClick={nextPage}>Next</button>}
            {page == formData.length - 2 && <button class="btn btn-success btn-lg px-4 me-sm-3" onClick={nextPage}>Submit information</button>} */}
    </form>
    );
};

export default Form;
