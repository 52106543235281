import React from "react";
import { Building, People, ClockHistory, Calendar3 } from 'react-bootstrap-icons';


function StatsCard(props) {
    return <div class="col-xl-3 col-sm-6 col-12 mb-1">
        <div class="card">
            <div class="card-content">
                <div class="card-body">
                    <div class="media d-flex">
                        <div class="me-auto align-self-center">
                            <>
                                {props.icon}
                            </>
                        </div>
                        <div class="media-body text-end">
                            <h3 class="text-danger">{props.heading}</h3>
                            <span>{props.subtitle}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default StatsCard;  