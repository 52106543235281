import React from "react";
import {
    Link
} from "react-router-dom";
import Form from "../dynamicForms/Form.jsx";
import { FormData } from "./data/FormData";
import AnimatedPages from "./AnimatedPages";
function Questionnaire(props) {
    return (<section data-aos="fade-up" class="bg-primary" id="services">
            <div id="about" style={{ "min-height": "70vh" }}>
                {/* style={{"height": "100vh"}} */}
                <div class="container px-5 my-5">
                    <div class="row gx-5">
                        <div class="col-lg-12 mb-5 mb-lg-0">
                            <Form formData={FormData} />

                        </div>

                    </div>
                </div>

            </div>
        </section>
    )
}

export default Questionnaire;  