import React from "react";
import {
    Link
} from "react-router-dom";
import ServiceSummaryLink from '../../components/ServiceSummaryLink.js'
// import services from '../data/ServicesData.js'
function AllServicesSummary(props) {
    return <section class="bg-primary py-5 border-bottom" id="services">
        {props.pageElements.services.map(item => {
            return (
                <>
                    <ServiceSummaryLink title={`${item.title}`} description={`${item.description}`} link={`${item.link}`} />
                </>
            )
        })}


        <div class="container px-5 my-5">
            <div class="row gx-5">
                <div class="col-lg-3 mb-5 mb-lg-0">
                    <Link class="btn btn-outline-light btn-lg px-4" to="/services">Back</Link>
                </div>
                <div class="col-lg-5 mb-5 mb-lg-0">
                </div>
                <div class="col-lg-4  mb-5 mb-lg-0">
                </div>
            </div>
        </div>

    </section >;
}

export default AllServicesSummary;  