import React from "react";
import { Building, People, ClockHistory, Calendar3 } from 'react-bootstrap-icons';
import {
    Link
} from "react-router-dom";

function ServiceButtons(props) {
    if (props.hide) {
        return <></>
    }
    else {
        return <div className="text-left my-5">
            <div class="row gx-5">
                <div className="col-lg-4 mb-5 mb-lg-0">
                    <Link className="btn btn-dark btn-lg px-4 me-sm-3" to="/get-started">Get started</Link>
                    <Link className="btn btn-dark btn-lg px-4 me-sm-3 mx-1" to="/services">Back</Link>
                </div>
                <div className="col-lg-8 mb-5 mb-lg-0">
                </div>
            </div>
        </div>
    }
}

export default ServiceButtons;  