import React from "react";
import {
    Link
} from "react-router-dom";

function ServiceSummaryLink(props) {
    return <div class="container px-5 my-5">
        <div class="row gx-5">
            <div class="col-lg-3 mb-5 mb-lg-0">
                <h2 class="display-10 text-white mb-4">{props.title}</h2>
            </div>
            <div class="col-lg-5 mb-5 mb-lg-0">
                <p class="text-white-50">{props.description}</p>
            </div>
            <div class="col-lg-4  mb-5 mb-lg-0">
                <Link class="text-white" to={`/services/${props.link}`}> <i class="bi bi-plus"></i> Learn more</Link>
            </div>
        </div>
    </div>
}

export default ServiceSummaryLink;  