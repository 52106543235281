import React from "react";
import {
    Link
} from "react-router-dom";
import Titlebar from './svg/Titlebar'


function Service(props) {
    //Sub-summaries
    let subSummarieBlocks;
    if (props.pageElements.serviceSubSummaryArray.length > 0) {

        subSummarieBlocks = <div>
            {props.pageElements.serviceSubSummaryArray.map(subSummary => {
                let subSummaries;
                if (subSummary.subSummaries.length > 0) {
                    subSummaries = <div class="container px-5 my-5">
                        {subSummary.subSummaries.map(item => {
                            return (
                                <>
                                    {item}
                                </>
                            )
                        })}

                    </div>;
                } else {
                    subSummaries = <></>;
                }

                return (
                    <div data-aos="fade-up" >
                        <div class="container px-5 my-5">
                            <div class="row gx-5">
                                <div class="col-lg-12 mb-5 mb-lg-0">
                                    <h2 class="display-6 text-white-50">{subSummary.subSummaryTitle}</h2>
                                </div>
                            </div>
                        </div>
                        {subSummaries}
                        <br /><br />
                        <hr class="text-white" />
                    </div>
                )
            })}
        </div>;
    } else {
        subSummarieBlocks = <></>;
    }

    let stats;

    //Stats
    if (props.pageElements.statsArray.length > 0) {
        stats = <div data-aos="fade-up" class="my-5">
            <div class="row">
                {props.pageElements.statsArray.map(item => {
                    return (
                        <>
                            {item}
                        </>
                    )
                })}
            </div>
        </div>;
    } else {
        stats = <></>;
    }

    let summaries;
    //Summary
    if (props.pageElements.serviceSummaryArray.length > 0) {
        summaries = <div data-aos="fade-up" class="container px-5 my-5">
            {props.pageElements.serviceSummaryArray.map(item => {
                return (
                    <>
                        {item}
                    </>
                )
            })}
            {/* Stat Cards */}
            {stats}
            {/* Buttons */}
            <>
                {props.pageElements.serviceButtons}
            </>

        </div>;
    } else {
        summaries = <></>;
    }

    return <section class="bg-primary py-5" id="services">
        <div class="container px-5 my-5">
            <div class="row gx-5">
                <div class="col-lg-12 mb-5 mb-lg-0">
                    <h2 class="display-5 text-success mb-4">{props.pageElements.title}</h2>
                </div>
            </div>
        </div>
        {/* subSummariesBlocks */}
        {subSummarieBlocks}
        {/* Service Summary */}
        {summaries}
    </section>
}

export default Service;  