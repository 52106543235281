import React, { useRef } from "react";
import {
    Link
} from "react-router-dom";
import uuid from 'react-uuid'
import AnimatedPages from "./AnimatedPages";

function About(props) {
    const titleRef = useRef();

    const handleBackClick = () => {
        titleRef.current.scrollIntoView({ behavior: 'smooth' })
    };

    return <section data-aos="fade-up" ref={titleRef} className="bg-primary py-5" id="about">
        <div id="about" className="aboutSection">
            <div class="container px-5 my-5">
                <div class="row gx-5">
                    <div class="col-lg-12 mb-5 mb-lg-0">
                        <h2 class="display-5 text-white-50 mb-4">{props.pageElements[0].title}</h2>
                    </div>

                </div>
            </div>
            {/* Titles */}
            {props.pageElements[0].serviceSummaryArray.map(item => {
                return (
                    <>
                        <div key={uuid()} class="container px-5 my-5">
                            {item}
                        </div>
                    </>
                )
            })}
            {/* Stat cards */}
            <div class="container px-5 my-5">
                <div class="my-5">
                    <div class="row">
                        {props.pageElements[0].statsArray.map(item => {
                            return (
                                <>
                                    {item}
                                </>
                            )
                        })}
                    </div>
                </div>
                {/* Buttons */}
                <>
                    {props.pageElements[0].serviceButtons}
                </>
            </div>
        </div>
    </section>;
}
export default About; 