import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";

import NotFound from './NotFound.js'
import Services from './Services.js'
import About from './About.js'
import Allpages from './AllPages.js'
import Contact from './Contact.js'
import Privacy from './Privacy.js'
import Questionnaire from './Questionnaire'
import DiscoveryCall from './DiscoveryCall'
import ScheduleCall from './ScheduleCall'
import AllServicesSummary from './services/All-Services-Summary.js'
import Service from '../components/Service'
import uuid from 'react-uuid'

export default function RoutesWrapper(props) {
  const { pathname } = useLocation();
  return (
    <Routes location={pathname}>
      <Route key={uuid()} exact path="/" element={<Allpages data={props.data} />} />
      <Route key={uuid()} exact path="/home" element={<Allpages data={props.data} />} />
      <Route key={uuid()} path="/about" element={<About pageElements={props.data.pageData.about} />} />
      <Route key={uuid()} path="/services" element={<Services pageElements={props.data.pageData.services} />} />
      <Route key={uuid()} path="/contact" element={<Contact data={props.data} />} />
      <Route key={uuid()} path="/privacy" element={<Privacy data={props.data} />} />
      <Route key={uuid()} path="/q" element={<Questionnaire />} />
      <Route key={uuid()} path="/get-started" element={<Questionnaire />} />
      <Route key={uuid()} path="/discovery-call-thank-you" element={<DiscoveryCall data={props.data.pageData.discoveryCall} />} />
      <Route key={uuid()} path="/schedule-call" element={<ScheduleCall data={props.data.pageData.discoveryCall} />} />
      {/* Service Routes */}
      {props.data.pageData.services.map(item => {
        return (
          <Route key={uuid()} path={`/services/${item.link}`} element={<Service pageElements={item} />} />
        )
      })}
      <Route key={uuid()} path="/services/all-services" element={<AllServicesSummary pageElements={props.data.pageData.services} />} />
      {/* Page not found route */}
      <Route key={uuid()} path='*' element={<NotFound />} />
    </Routes>
  );
}