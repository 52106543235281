import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Firebase from 'firebase'
import { useFormik } from 'formik'
import * as Yup from "yup";
import { Modal, Button } from 'react-bootstrap';
import { Pin, Telephone, Envelope, Textarea } from 'react-bootstrap-icons';
import ReCaptchaV2 from 'react-google-recaptcha'
import uuid from 'react-uuid'
// import Lottie from "lottie-react";
// import icon_phone from "../resources/lotties/icon_phone.json";
// import icon_place from "../resources/lotties/icon_place.json";
// import icon_write_white from "../resources/lotties/icon_write_white.json";


const FeedbackSchema = Yup.object().shape({

    name: Yup.string()
        .min(2, 'Too Short!')
        .max(70, 'Too Long!')
        .required('Required'),

    email: Yup.string()
        .email('Invalid email')
        .required('Required'),

});

const Contact = props => {
    const [show, setShow] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const recaptchaSiteKey = "6Ld9DGgeAAAAAGTuKb_q96N1jiaii-6_oQJluNOl";
    const handleToken = (token) => {
        //console.log(token);
        setRecaptchaToken(token);
    }

    const handleExpire = () => {
        //console.log("Token expired");
        setRecaptchaToken("");
    }

    var regexEmail = new RegExp("");
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
    } = props;

    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            name: '',
            company: '',
            email: '',
            phone: '',
            message: '',
        },
        onSubmit: (values, { props, resetForm, setErrors, setSubmitting }) => {
            var err = false;
            var phoneLengthErr = false;
            var emailLengthErr = false;
            var errObj = {};
            if (values.name === '' || values.name.trim().length <= 1) {
                //setErrors({ name: 'Name cannot be empty' });
                errObj.name = 'Name is required.';
                err = true;
            }
            if (values.company === '' || values.company.trim().length <= 1) {
                //setErrors({ name: 'Name cannot be empty' });
                errObj.company = 'Company is required.';
                err = true;
            }
            if (values.phone === '' || values.phone.trim().length < 10) {
                //setErrors({ phone: 'Please check the phone number' });
                errObj.phone = 'Please check the phone number.';
                phoneLengthErr = true;
                err = true;
            }
            // if (!phoneLengthErr && !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/i.test(values.phone)) {
            //     //setErrors({ phone: 'Please check the phone number' });
            //     errObj.phone = 'Please check the phone number format';
            //     err = true;
            // }
            if (values.email === '' || values.email.trim().length <= 5) {
                //setErrors({ email: 'Email address is too short' });
                errObj.email = 'Email address is too short';
                err = true;
            }
            if (!emailLengthErr && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                //setErrors({ email: 'Please check the email' });
                errObj.email = 'Please check the email address.';
                err = true;
            }
            if (values.message === '' || values.message.trim().length < 1) {
                //setErrors({ message: 'Please check the message' });
                errObj.message = 'A message is required.';
                err = true;
            }
            if (err) {
                setErrors(errObj);
            }

            if (!err) {

                document.getElementById("submitButton").disabled = true;
                // Post Data
                const requestOptions = {
                    method: 'POST',
                    //mode: 'same-origin', // no-cors, *cors, same-origin
                    // redirect: 'follow',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    body: JSON.stringify({
                        "data": {
                            name: values.name,
                            company: values.company,
                            email: values.email,
                            phone: values.phone,
                            message: values.message,
                            timestamp: Date.now(),
                            uuid: uuid(),
                        },
                        "mode": "contact",
                        recaptchaToken
                    })
                };
                fetch('/checkRecaptcha', requestOptions)
                    .then(response => {
                        //console.log(response);
                        handleExpire();
                        resetForm();
                        handleShow();
                    })

                    .catch(function (error) {
                        console.error("Message could not be sent: ", error);
                    });
            }
            document.getElementById("submitButton").disabled = false;
        },
    });
    return (<section data-aos="fade-up" class="bg-primary py-5" id="contact">
            <Modal show={show} onHide={handleClose}>
                <Modal.Header className="bg-primary" closeButton>
                    <Modal.Title className="text-white">Thank you</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-primary text-white-50"> Thanks for sending us a message. We'll get back to you as soon as possible.</Modal.Body>
                <Modal.Footer className="bg-primary text-white">
                    <Button className="btn btn-dark" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <div class="container px-5 my-5 px-5">
                <div class="text-left mb-5 pb-2">

                    <h2 class="display-5 text-white-50 mb-4">Get in touch</h2>
                    <p class="display-50 text-white-50">We look forward to hearing from you</p>
                </div>
                <div class="row gx-5 justify-content-center">
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-2">
                                <div class="bg-primary text-white rounded-3 mb-3"><Pin class="bi" /></div>
                            </div>
                            <div class="col">
                                <h1 class="lead text-white mb-2">{props.data.companyFullName}</h1>
                                <h1 class="lead text-white mb-2">{props.data.address.line1}</h1>
                                <h1 class="lead text-white mb-2">{props.data.address.line2}</h1>
                                <h1 class="lead text-white mb-2">{props.data.address.city}, {props.data.address.state} {props.data.address.zipCode}</h1>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-2">
                                <div class="bg-primary text-white rounded-3 mb-3 pt-5"><Telephone class="bi" /></div>
                            </div>
                            <div class="col">
                                <p class="display-10 text-white mb-4 pt-5">{props.data.tel}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-2">
                                <div class="bg-primary text-white rounded-3 mb-3 pt-5  pb-1"><Envelope class="bi" /></div>
                            </div>
                            <div class="col">
                                <p class="display-10 text-white mb-4 pt-5 pb-5">{props.data.email}</p>
                                <div class="d-grid gap-3 d-sm-flex justify-content-sm-left"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <form onSubmit={formik.handleSubmit}>
                            <div class="form-floating mb-3">
                                <input
                                    class="form-control"
                                    placeholder="Enter your name..."
                                    id="name"
                                    name="name"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                />
                                {formik.errors.name && formik.touched.name && <div class="text-white" id="feedback">{formik.errors.name}</div>}
                                <label htmlFor="name">Full name</label>
                                <div class="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
                            </div>
                            <div class="form-floating mb-3">
                                <input
                                    class="form-control"
                                    placeholder="Company..."
                                    id="company"
                                    name="company"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.company}
                                />
                                {formik.errors.company && formik.touched.company && <div class="text-white" id="feedback">{formik.errors.company}</div>}
                                <label htmlFor="name">Company</label>
                                <div class="invalid-feedback" data-sb-feedback="name:required">Company name is required.</div>
                            </div>
                            <div class="form-floating mb-3">
                                <input
                                    class="form-control"
                                    placeholder="Enter your phone number..."
                                    id="phone"
                                    name="phone"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.phone}
                                />
                                {formik.errors.phone && formik.touched.phone && <div class="text-white" id="feedback">{formik.errors.phone}</div>}
                                <label htmlFor="phone">Phone number</label>
                                <div class="invalid-feedback">A phone number is required.</div>
                            </div>
                            <div class="form-floating mb-3">
                                <input
                                    class="form-control"
                                    placeholder="Enter your email..."
                                    id="email"
                                    name="email"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                />
                                {formik.errors.email && formik.touched.email && <div class="text-white" id="feedback">{formik.errors.email}</div>}
                                <label htmlFor="email">Email</label>
                                <div class="invalid-feedback" data-sb-feedback="email:required">A email is required.</div>
                            </div>
                            <div class="form-floating mb-3">
                                <textarea class="form-control"
                                    style={{ height: '10rem' }}
                                    placeholder="Enter your message..."
                                    id="message"
                                    name="message"
                                    type="textArea"
                                    onChange={formik.handleChange}
                                    value={formik.values.message}></textarea>
                                {formik.errors.message && formik.touched.message && <div class="text-white" id="feedback">{formik.errors.message}</div>}
                                <label htmlFor="message">Please specify your requirements.</label>
                                <div class="invalid-feedback" data-sb-feedback="message:required">A message is required.
                                </div>
                            </div>
                            <div id="recaptcha" class="form-floating mb-3">
                                <ReCaptchaV2
                                    sitekey={recaptchaSiteKey}
                                    onChange={handleToken}
                                    onExpire={handleExpire} />
                            </div>
                            <div class="d-grid"><button class="btn btn-dark btn-lg disable" id="submitButton"
                                type="submit">Submit</button></div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;