import React from "react";
import { Accordion } from 'react-bootstrap';
import { ListCheck, People, ClockHistory, ShieldLock, LayoutWtf, Search, PatchCheck } from 'react-bootstrap-icons';
function CapabilitiesAccordion(props) {
    return (
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header className="bg-primary"><h4><ShieldLock className="me-3" />Security & privacy</h4></Accordion.Header>
                <Accordion.Body bg="dark" className="bg-primary">
                    <code className="text-white">
                        ● ISO 27001<br></br>
                        ● ISO 27701/GDPR<br></br>
                        ● PCI DSS<br></br>
                        ● ISO 27017<br></br>
                        ● ISO 27018<br></br>
                        ● HIPAA<br></br>
                        ● SWIFT<br></br>
                        ● SOC- 2<br></br>
                        ● NIST Cyber Security Framework
                    </code>
                </Accordion.Body>
            </Accordion.Item>
            <br />
            <Accordion.Item eventKey="1">
                <Accordion.Header className="bg-primary"><h4><LayoutWtf className="me-3" />Quality, health, safety, & environment</h4></Accordion.Header>
                <Accordion.Body bg="dark" className="bg-primary">
                    <code className="text-white">
                        ● ISO 9001<br></br>
                        ● ISO 14001/ROHS<br></br>
                        ● ISO 45001<br></br>
                        ● ISO 29001<br></br>
                        ● TS 16949<br></br>
                    </code>
                </Accordion.Body>
            </Accordion.Item>
            <br />
            <Accordion.Item eventKey="2">
                <Accordion.Header className="bg-primary"><h4><ClockHistory className="me-3" />Food safety</h4></Accordion.Header>
                <Accordion.Body bg="dark" className="bg-primary">
                    <code className="text-white">
                        ● ISO 22001<br></br>
                        ● BRC Food<br></br>
                        ● BRC Packaging<br></br>
                        ● FSSC<br></br>
                        ● IFS<br></br>
                    </code>
                </Accordion.Body>
            </Accordion.Item>
            <br />
            <Accordion.Item eventKey="3">
                <Accordion.Header className="bg-primary"><h4><People className="me-3" />Other management systems</h4></Accordion.Header>
                <Accordion.Body bg="dark" className="bg-primary">
                    <code className="text-white">
                        ● ISO 22301 - Business continuity management system<br></br>
                        ● ISO 50001 - Energy management system<br></br>
                        ● ISO 20000 - Service management system<br></br>
                        ● ISO 26001 - Social accountability system<br></br>
                        ● ISO 55001 - Asset management system<br></br>
                    </code>
                </Accordion.Body>
            </Accordion.Item>
            <br />
        </Accordion>
    )
}

export default CapabilitiesAccordion;  