import React, { Fragment } from "react";
import AnimatedPages from "../pages/AnimatedPages";

const Select = ({ field, fieldChanged, value, fieldValidate }) => {
  return (<AnimatedPages>
    <div data-bs-toggle="tooltip" className={field.colSize != null && field.colSize ? field.colSize : "col-lg-12"} key={field._uid}
      onChange={(e) => {
        fieldChanged(field._uid, e.target.value);
      }}
    >
      {field.hideLabel != null && field.hideLabel ? "" : <p className="text-white-50">{field.label}</p>}
      <select className="form-select form-select-lg" id={field._uid}>
        {field.options.map((option, index) => {
          return (
            <Fragment key={option.value}>
              <option
                id={option.value}
                name={field._uid}
                value={option.value}
                selected={value === option.value}
                onChange={(e) => {
                  fieldChanged(field._uid, e.target.value);
                }}
              >{option.label}</option>
              {/* {option.label}
            {index < field.options.length - 1 && <br />} */}
            </Fragment>
          );
        })}
      </select><br />
    </div></AnimatedPages>
  );
};



export default Select;
