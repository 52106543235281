import React from "react";
import {
    Link
} from "react-router-dom";
import AnimatedPages from "./AnimatedPages";
import Lottie from "lottie-react";
import fourofour from "../resources/lotties/404.json";

function NotFound(props) {
    return <AnimatedPages><header class="bg-primary py-5 border-bottom">
        <div class="container px-5 ">
            <div className="col-lg-12 text-left"><Lottie style={{ height: '20em', textAlign: 'center' }} loop={true} animationData={fourofour} /></div>;
            <div class="row gx-5 justify-content-center">
                <div class="col-lg-6 ">
                    <div class="text-left">
                        <h1 class="lead text-white mb-2">404</h1>
                        <p class="display-5 text-white-50 mb-4">Page not found. </p>
                        <div class="d-grid gap-3 d-sm-flex justify-content-sm-left">

                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="text-left">
                        {/* <!-- <h1 class="display-5 fw-bolder text-white mb-2">We are an established finance company that provides acquisition, development and finance expertise for both commercial and multi-family projects.</h1> --> */}
                        <p class="lead text-white-50 mb-4">The page you've requested was not found. Please check the address and retry.</p>
                        <div class="d-grid gap-3 d-sm-flex justify-content-sm-left pt-5 pb-5">
                            <Link class="btn btn-dark btn-lg px-4 me-sm-3" to="/home">Home</Link>
                            {/* <Link class="btn btn-dark btn-lg px-4 me-sm-3" to="/about">Learn More</Link> */}
                            {/* <!-- <a class="btn btn-outline-light btn-lg px-4" href="#!">Learn More</a> --> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header></AnimatedPages>;
}

export default NotFound;  