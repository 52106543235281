import React from "react";
import {
    Link
} from "react-router-dom";
import TitleCard from "../dynamicForms/TitleCard";
import Lottie from "lottie-react";
import yellowCheck from "../resources/lotties/yellow_check-2.json";
import { InlineWidget } from "react-calendly";

function ScheduleCall(props) {
    return <header  data-aos="fade-up" class="bg-primary py-5">
        <div class="container px-5  pt-5 pb-5" id="home">
            <div class="row gx-5 justify-content-center">
                <InlineWidget styles={{
                    height: '80vh'
                }}
                    pageSettings={{
                        backgroundColor: '172179',//000633
                        hideEventTypeDetails: false,
                        hideLandingPageDetails: false,
                        primaryColor: 'FFC857',
                        textColor: '8B90BC'
                    }}
                    url="https://calendly.com/certiallc/discovery" />
            </div>
        </div>
    </header>
}

export default ScheduleCall;  