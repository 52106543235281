import React, { useState } from "react";
import { Accordion, Carousel } from 'react-bootstrap';
import { ListCheck, People, ClockHistory, Calendar3, JournalCheck, LayoutWtf, Search, PatchCheck } from 'react-bootstrap-icons';
// import Lottie from "lottie-react";
// import background from "../../resources/lotties/background1.json";
import background from "../../resources/images/bg-slide.PNG";
function AuditProcessCarousel(props) {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel fade activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item className="bg-success" id="1c">
                {/* <Lottie  id="1" style={{ height: '20em', textAlign: 'center' }} loop={true} animationData={background} /> */}
                <img src={background} style={{ minHeight: '100%', minWidth: '100%', textAlign: 'center' }}></img>
                <Carousel.Caption id="1cc">
                    <h1 style={{ fontSize: '5em' }} className="mb-2">1</h1>
                    <h3 className="mb-5">Questionnaire</h3>
                    <br class="mobile-break"/>
                    <p className="mb-5 mobile-font-size">Understanding your environment and criteria for audit, capacity, and number of resources required.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item id="2c" >
                <img src={background} style={{ minHeight: '100%', minWidth: '100%', textAlign: 'center' }}></img>
                <Carousel.Caption id="2cc" >
                    <h1 style={{ fontSize: '5em' }} className="mb-2">2</h1>
                    <h3 className="mb-5">Review Scope</h3>
                    <br class="mobile-break"/>
                    <p className="mb-5 mobile-font-size"> Understanding your audit approach, familiarization with your audit tools, methodology, and calibration of auditors.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item id="3c">
                <img src={background} style={{ minHeight: '100%', minWidth: '100%', textAlign: 'center' }}></img>
                <Carousel.Caption id="3cc">
                    <h1 style={{ fontSize: '5em' }} className="mb-2">3</h1>
                    <h3 className="mb-4">Estimation</h3>
                    <br class="mobile-break"/>
                    <p className="mb-5 mobile-font-size">We'll provide you a detailed estimation on auditors required to meet your audit capacity, and their per hour rate of billing.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item id="4c">
                <img src={background} style={{ minHeight: '100%', minWidth: '100%', textAlign: 'center' }}></img>
                <Carousel.Caption id="4cc">
                    <h1 style={{ fontSize: '5em' }} className="mb-2">4</h1>
                    <h3 className="mb-3">Planning & selection</h3>
                    <br class="mobile-break"/>
                    <p className="mb-5 mobile-font-size">Identify competent auditors based on audit criteria, training and on-boarding of auditors, familiarization with your audit tools/ reporting requirements. This is followed by an approval from your team.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item id="5c">
                <img src={background} style={{ minHeight: '100%', minWidth: '100%', textAlign: 'center' }}></img>
                <Carousel.Caption id="5cc">
                    <h1 style={{ fontSize: '5em' }} className="mb-2 mobile-font-size">5</h1>
                    <h3 className="mb-5">Auditing</h3>
                    <br class="mobile-break"/>
                    <p className="mb-5 mobile-font-size">Each auditor will carry out the audit for the assigned client based on the criteria, log audit details, queries/ clarifications, audit issues.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item id="6c" id="6cc">
                <img src={background} style={{ minHeight: '100%', minWidth: '100%', textAlign: 'center' }}></img>
                <Carousel.Caption>
                    <h1 style={{ fontSize: '5em' }} className="mb-2">6</h1>
                    <h3 className="mb-3">Weekly reporting & review</h3>
                    <br class="mobile-break"/>
                    <p className="mb-4 mobile-font-size">Weekly review of audit artifacts, auditor performance, productivity, and auditor development plan. This will be followed by approval of auditor time-sheets.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item id="7c">
                <img src={background} style={{ minHeight: '100%', minWidth: '100%', textAlign: 'center' }}></img>
                {/* <Lottie id="7" style={{ height: '20em', textAlign: 'center' }} loop={true} animationData={background} /> */}
                <Carousel.Caption id="7cc">
                    <h1 style={{ fontSize: '5em' }} className="mb-2">7</h1>
                    <h3 className="mb-3">Monthly audit performance review</h3>
                    <br class="mobile-break"/><br class="mobile-break"/>
                    <p className="mb-5 mobile-font-size">Each month we'll perform a status review so that we are in alignment with your standards and requirements, work on audit improvement plans.</p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>

    );
}

export default AuditProcessCarousel;  