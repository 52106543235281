import React, { Fragment } from "react";

const Options = ({ field, fieldChanged, value }) => {
  return (
    <div className={field.colSize != null && field.colSize ? field.colSize : "col-lg-12"}>
      <div className="form-radio">
        <h3 className="text-white-50">{field.label}</h3>
        <br /><br />
        {field.options.map((option, index) => {
          return (
            <Fragment key={option.value}>
              <label className="text-white-50" htmlFor={option.value}>
                <input className=""
                  type={field.type}
                  id={field._uid}
                  name={field._uid}
                  value={option.value}
                  checked={value === option.value}
                  onChange={(e) => {
                    fieldChanged(field._uid, e.target.value);
                  }}
                />
                <label className="form-radio-label px-3" for="inlineRadio1">{option.label}</label>
              </label>
              {index < field.options.length - 1 && <br />}
            </Fragment>
          );
        })}
        <br /></div>
    </div>
  );
};

export default Options;
