import React, { Fragment } from "react";

const Checkbox = ({ field, fieldChanged, value, fieldGrpId }) => {
  var classAdd = "";
  if (value === field.value) {
    classAdd = " selected-card";
  }
  return (
    <div id={"checkBoxCard"} className={field.colSize != null && field.colSize ? field.colSize : "col-lg-12"}>
      <div id={"div_" + field._uid} class={"fade-in-card card border-dark mb-3" + classAdd}>
        <label class="card-body">
          <div className="row text-left">
            <div className=" form-switch col-lg-1" style={{ width: '10px', textAlign: 'center' }}>

              <input className="form-check-input"
                type={field.type}
                id={field._uid}
                name={field._uid}
                value={field.value}
                checked={value === field.value}
                onChange={(e) => {
                  fieldChanged(field._uid, { checked: e.target.checked, value: e.target.value, fieldGrpId: fieldGrpId });
                }}
              />
            </div>
            <div className="col-lg-10" style={{ width: '70%' }}>
              <label className=" text-dark px-2" for={field._uid}>{field.label}</label>
            </div>
          </div>
        </label>
      </div>

    </div>

  );
};

export default Checkbox;
