import React from "react";
import {
    Link
} from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import AnimatedPages from "../pages/AnimatedPages";
import Lottie from "lottie-react";
import yellowCheck from "../resources/lotties/yellow_check-2.json";

function TitleCard(props) {
    let animElement;
    if (props.animation != undefined) {
        animElement = (
            <>
                {props.animation}
            </>
        )

    } else {
        animElement = (<></>)
    }

    return <AnimatedPages><header class="bg-primary">

        <div className="col-lg-12 pb-5 text-left mobile-font-size" > {animElement}</div>;
        {/* <div className="col-lg-12 pb-5 text-left" ><Lottie style={{ height: '15em', textAlign: 'center' }} loop={true} animationData={yellowCheck} /></div>; */}
        <div class="pb-5">
            <div class="row gx-5 justify-content-center">

                <div class="col-lg-6">
                    <div class="text-left">

                        <p class="display-5 text-white-50"><ReactMarkdown>{props.title}</ReactMarkdown></p>
                        <div class="d-grid gap-3 d-sm-flex justify-content-sm-left">
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="text-left">

                        <p class="lead text-success">{props.subTitle}</p>
                        <div class="d-grid gap-3 d-sm-flex justify-content-sm-left pt-5 pb-5">
                            {/* <Link class="btn btn-dark btn-lg px-4 me-sm-3" to={props.nextPage}>Get Started</Link> */}
                            <button class="btn btn-outline-success btn-lg px-4 me-sm-3" hidden={props.hideButton} onClick={props.nextPage}>Continue</button>
                        </div>
                        <p hidden={props.hideButton} class="lead text-success">Alternatively, if you would like to schedule a 30-minute phone call with us, please click the button below.</p>
                        <div hidden={props.hideButton} class="d-grid gap-3 d-sm-flex justify-content-sm-left pt-5 pb-5">
                            <Link hidden={props.hideButton} class="btn btn-outline-success btn-lg px-4 me-sm-3" to="/schedule-call">Schedule a call</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header></AnimatedPages>;
}

export default TitleCard;  