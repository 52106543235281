import React from "react";
import { Building, People, ClockHistory, Calendar3 } from 'react-bootstrap-icons';
import AuditProcessAccordion from '../components/accordions/AuditProcessAccordion'
import { Accordion } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown'

function ServiceSummary(props) {
    return <div class="my-5">
        <div class="row gx-5">
            <div class="col-lg-4 mb-5 mb-lg-0">
                <h2 class="display-10 text-white mb-4"><ReactMarkdown>{props.title}</ReactMarkdown></h2>
            </div>
            <div class="col-lg-8 mb-5 mb-lg-0">
                <p class="text-white-50"><ReactMarkdown>{props.description}</ReactMarkdown></p>
                <div class="row">
                    <div class="col-lg-12 text-white">
                        {props.accordion}
                    </div>
                    <div class="col-lg-12 text-white">
                        {props.svg}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ServiceSummary;  