import { EmojiAngryFill } from "react-bootstrap-icons";
import Lottie from "lottie-react";
import yellowCheck from "../../resources/lotties/yellow_check-2.json";
import formsAnimation from "../../resources/lotties/forms.json";
import uuid from 'react-uuid'

export const FormData = [
  //Title page
  {
    component: "page",
    label: "",
    _uid: "beginTitle",
    fields: [
      {
        component: "titleCard",
        title: "Hello, we've got some *questions* for you.",
        subTitle: "Please take a few moments to answer our questionnaire. We will get back to you with our approach and proposal.",
        type: "text",
        _uid: "f61231e8-565e-43d0-9c14-7d7f220c6020",
        animation: <Lottie style={{ height: '10em', textAlign: 'center' }} loop={true} animationData={formsAnimation} />
      },
      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  },
  //Basic contact info
  {
    component: "page",
    label: "Your contact info",
    _uid: "0c946643-5a83-4545-baea-055b27b51e8a",
    fields: [
      {
        colSize: "col-lg-8",
        component: "field_group",
        label: "",
        _uid: "eb169f76-4cd9-4513-b673-87c5c7d27e02",
        fields: [
          {
            component: "text",
            label: "First Name",
            type: "text",
            _uid: "contactDetails.firstName",
            validationParams: {
              req: true,
              regexp: "[a-zA-Z]{1,25}",
              errMsg: "Please check the first name entered"
            }
          },
          {
            component: "text",
            label: "Last Name",
            type: "text",
            _uid: "contactDetails.lastName",
            validationParams: {
              req: true,
              regexp: "[a-zA-Z]{1,25}",
              errMsg: "Please check the last name entered"
            }
          },
          {
            component: "text",
            label: "Business Email",
            type: "text",
            validationParams: {
              req: true,
              regexp: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$",
              errMsg: "Please check the email entered"
            },
            _uid: "contactDetails.businessEmail"
          },
          {
            component: "text",
            label: "Phone Number",
            type: "text",
            _uid: "contactDetails.phoneNumber",
            validationParams: {
              req: true,
              regexp: "",
              errMsg: "Please check the phone number entered"
            }
          },
          {
            component: "text",
            label: "Company Name",
            type: "text",
            validationParams: {
              req: true,
              regexp: "[a-zA-Z0-9-._!#%&,:;<>=@]{1,25}$",
              errMsg: "Please check the company name entered"
            },
            _uid: "contactDetails.companyName"
          },
          {
            hideLabel: true,
            component: "dropdowns",
            name: "Role",
            label: "Designation",
            type: "radio",
            _uid: "contactDetails.designation",
            validationParams: {
              req: true,
              regexp: "",
              errMsg: "Please check the selection"
            },
            options: [
              {
                component: "option",
                label: "Which role best describes yourself?",
                value: ""
              },
              {
                component: "option",
                label: "CEO",
                value: "CEO"
              },
              {
                component: "option",
                label: "CFO",
                value: "CFO"
              },
              {
                component: "option",
                label: "CIO",
                value: "CIO"
              },
              {
                component: "option",
                label: "CISO",
                value: "CISO"
              },
              {
                component: "option",
                label: "Compliance Professional",
                value: "Compliance Professional"
              },
              {
                component: "option",
                label: "IT Professional",
                value: "IT Professional"
              },
              {
                component: "option",
                label: "InfoSec Professional",
                value: "InfoSec Professional"
              },
              {
                component: "option",
                label: "Other (pls. specify)",
                value: "other"
              }
            ]
          }
        ]
      },
      {
        colSize: "col-lg-8",
        conditional: {
          value: "other",
          field:
            "conditional_contactDetails.designation"
        },
        component: "text",
        name: "Role",
        label: "Please specify your role",
        type: "text",
        _uid: "contactDetails.designationOther",
        validationParams: {
          req: true,
          regexp: "",
          errMsg: "Please fill out your role"
        }
      },
      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  },
  //Organization current certification info
  {
    component: "page",
    label: "Services provided & current compliance level of your organization?",
    _uid: "3a30803f-135f-442c-ab6e-d44d7d7a5164",
    fields: [
      {
        colSize: "col-lg-8",
        component: "textArea",
        label: "Services provided by the organization",
        type: "textArea",
        _uid: "page2.servicesProvided",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please fill out the services provided by your organization"
        }
      },
      {
        colSize: "col-lg-8",
        component: "field_group",
        hideTitle: false,
        validationParams: {
          req: true,
          regexp: "",
          errMsg: "Please select a valid option, select none if you do not have any"
        },
        label: "Please select all your current certifications",
        _uid: "page2.currentCertifications",
        fields: [
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "ISO 27001",
            type: "checkbox",
            _uid: "currentCertifications.iso27001",
            value: "Y",

          },
          {
            // colSize: "col-lg-6",
            component: "checkbox",
            label: "PCI DSS",
            type: "checkbox",
            _uid: "currentCertifications.pcidss",
            value: "Y",

          },
          {

            component: "checkbox",
            label: "ISO 22301",
            type: "checkbox",
            _uid: "currentCertifications.iso22301",
            value: "Y",

          },
          {

            component: "checkbox",
            label: "ISO 20000",
            type: "checkbox",
            _uid: "currentCertifications.iso20000",
            value: "Y",

          },
          {

            component: "checkbox",
            label: "GDPR/ISO 27701 ",
            type: "checkbox",
            _uid: "currentCertifications.iso27701",
            value: "Y",

          },
          {

            component: "checkbox",
            label: "FedRamp",
            type: "checkbox",
            _uid: "currentCertifications.fedramp",
            value: "Y",

          },
          {

            component: "checkbox",
            label: "Others (pls specify)",
            type: "checkbox",
            _uid: "currentCertifications.others",
            value: "Y",

          },
          {

            component: "checkbox",
            label: "None",
            type: "checkbox",
            _uid: "currentCertifications.none",
            value: "Y",

          }
        ]
      },
      {
        colSize: "col-lg-8",
        conditional: {
          value: "Y",
          field:
            "conditional_currentCertifications.others"
        },
        component: "textArea",
        name: "Current certification info",
        label: "What other certifications does your organization have?",
        type: "text",
        _uid: "currentCertifications.othersDetails",
        validationParams: {
          req: true,
          regexp: "",
          errMsg: "Please fill out all your current certifications"
        }
      },
      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  },
  //Page 4: Service scope
  {
    component: "page",
    label: "What services are you looking for?",
    _uid: "service.portfolio",
    fields: [
      {
        colSize: "col-lg-8",
        component: "field_group",
        hideTitle: true,
        validationParams: {
          req: true,
          regexp: "",
          errMsg: "Please select at least one service"
        },
        label: "Please choose at least one service",
        _uid: "services",
        fields: [
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "Audit as a service (AAAS)– Audit out-sourcing",
            type: "checkbox",
            _uid: "services.aaas",
            value: "Y",

          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "One time audit: Independent audit/ Internal audit",
            type: "checkbox",
            _uid: "services.audit",
            value: "Y",

          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "Advisory/Support services",
            type: "checkbox",
            _uid: "services.advisory",
            value: "Y",

          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "Training",
            type: "checkbox",
            _uid: "services.training",
            value: "Y",

          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "Certification ",
            type: "checkbox",
            _uid: "services.certification",
            value: "Y",

          }
        ]
      },
      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  },
  //Compliance requirement
  {
    component: "page",
    label: "What are your compliance requirements?",
    _uid: "3a30803f-135f-442c-ab6e-d44d7d7a5155",
    conditional: {
      value: "Y",
      field:
        "page_services.aaas$services.audit$services.advisory$services.certification~services.training"
    },
    fields: [
      {
        colSize: "col-lg-8",
        component: "field_group",
        hideTitle: false,
        validationParams: {
          req: true,
          regexp: "",
          errMsg: "Please select at least one compliance requirement"
        },
        label: "Please choose the compliance requirements",
        _uid: "eb169f76-4cd9-4513-b673-87c5c7d27e2",
        fields: [
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "ISO 27001",
            type: "checkbox",
            _uid: "complianceRequirement.iso27001",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "ISO 22301",
            type: "checkbox",
            _uid: "complianceRequirement.iso22301",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "PCI DSS",
            type: "checkbox",
            _uid: "complianceRequirement.pcidss",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "SOC-2",
            type: "checkbox",
            _uid: "complianceRequirement.soc2",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "Privacy/ GDPR / ISO 27701",
            type: "checkbox",
            _uid: "complianceRequirement.privacyGDPRiso27701",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "HIPAA",
            type: "checkbox",
            _uid: "complianceRequirement.hippa",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "Cyber security maturity assessment based on NIST 871/872",
            type: "checkbox",
            _uid: "complianceRequirement.csma",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "ISO 20000",
            type: "checkbox",
            _uid: "complianceRequirement.iso20000",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "VAPT",
            type: "checkbox",
            _uid: "complianceRequirement.vapt",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "App Security Assessment",
            type: "checkbox",
            _uid: "complianceRequirement.appSecAssessment",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "CISA Independent Audit",
            type: "checkbox",
            _uid: "complianceRequirement.cisa",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "NSAR",
            type: "checkbox",
            _uid: "complianceRequirement.nsar",
            value: "Y",
          }
          ,
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "Others (pls. specify)",
            type: "checkbox",
            _uid: "complianceRequirement.others",
            value: "Y",
          }
        ]
      },
      {
        colSize: "col-lg-8",
        conditional: {
          value: "Y",
          field:
            "complianceRequirement.others"
        },
        component: "textArea",
        name: "Other certification details",
        label: "What other compliance are you looking for?",
        type: "text",
        _uid: "complianceRequirement.othersDetails",
        validationParams: {
          req: true,
          regexp: "",
          errMsg: "Please fill out the compliance you are looking for"
        }
      },
      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  },
  //Conditional - Compliance for Training
  {
    component: "page",
    label: "What standards are you looking to get trained on?",
    _uid: "3a30803f-135f-442c-ab6e-d44d7d7a5156",
    conditional: {
      value: "Y",
      field:
        "page_services.training"
    },
    fields: [
      {
        colSize: "col-lg-8",
        component: "field_group",
        hideTitle: false,
        validationParams: {
          req: true,
          regexp: "",
          errMsg: "Please select at least one compliance standard"
        },
        label: "Please choose the compliance standards",
        _uid: "eb169f76-4cd9-4513-b673-87c5c7d27e2",
        fields: [
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "ISO 27001",
            type: "checkbox",
            _uid: "complianceRequirementTraining.iso27001",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "ISO 22301",
            type: "checkbox",
            _uid: "complianceRequirementTraining.iso22301",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "PCI DSS",
            type: "checkbox",
            _uid: "complianceRequirementTraining.pcidss",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "SOC-2",
            type: "checkbox",
            _uid: "complianceRequirementTraining.soc2",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "Privacy/ GDPR / ISO 27701",
            type: "checkbox",
            _uid: "complianceRequirementTraining.privacyGDPRiso27701",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "HIPAA",
            type: "checkbox",
            _uid: "complianceRequirementTraining.hippa",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "CSMA - NIST 871/872",
            type: "checkbox",
            _uid: "complianceRequirementTraining.csma",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "ISO 20000",
            type: "checkbox",
            _uid: "complianceRequirementTraining.iso20000",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "VAPT",
            type: "checkbox",
            _uid: "complianceRequirementTraining.vapt",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "App Security Assessment",
            type: "checkbox",
            _uid: "complianceRequirementTraining.appSecAssessment",
            value: "Y",
          },
          {
            //colSize: "col-lg-12",
            component: "checkbox",
            label: "Others (pls. specify)",
            type: "checkbox",
            _uid: "complianceRequirementTraining.others",
            value: "Y",
          }
        ]
      },
      {
        colSize: "col-lg-8",
        conditional: {
          value: "Y",
          field:
            "complianceRequirementTraining.others"
        },
        component: "textArea",
        name: "Other compliance standard details",
        label: "What other compliance standard are you looking to get trained on?",
        type: "text",
        _uid: "complianceRequirementTraining.othersDetails",
        validationParams: {
          req: true,
          regexp: "",
          errMsg: "Please fill out the compliance you are looking for"
        }
      },
      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  },
  //Conditional - Common questions
  {
    component: "page",
    label: "Let's understand your organization",
    _uid: "common.questions",
    conditional: {
      value: "Y",
      field: "services.audit$services.advisory$services.certification$complianceRequirement.iso27001$complianceRequirement.iso22301$complianceRequirement.pcidss$complianceRequirement.soc2$complianceRequirement.iso27701$complianceRequirement.hippa$complianceRequirement.csma$complianceRequirement.iso20000$complianceRequirement.cisa$complianceRequirement.nsar~services.training"
    },
    fields: [
      {
        colSize: "col-lg-8",
        component: "textArea",
        label: "For what services, domain, or application of your organization do you need our services?",
        type: "textArea",
        _uid: "common.serviceScope",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please fill out the required scope boundary of our services"
        }
      },
      {
        colSize: "col-lg-8",
        component: "text",
        label: "Number of locations to be covered under the current scope?",
        type: "text",
        _uid: "common.numberOfLLocations",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please check the number of locations entered"
        }
      },
      {
        colSize: "col-lg-8",
        component: "text",
        label: "Number of total employees (+ contractors) covering the scope?",
        type: "text",
        _uid: "common.numberOfEmployees",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please check the number of total employees entered"
        }
      },
      {
        colSize: "col-lg-8",
        component: "textArea",
        label: "Please also provide us with location wise activity details as performed by the organization (if applicable)",
        type: "textArea",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please check the email entered"
        },
        _uid: "common.locationActivityDetails"
      },
      {
        colSize: "col-lg-8",
        component: "field_group",
        label: "",
        _uid: "eb169f76-4cd9-4513-b673-87c5c7d27e02",
        fields: [
          {
            component: "dropdowns",
            label: "Is your IT infrastructure on-premise or on-cloud?",
            type: "text",
            _uid: "common.isITInfraOutsourced",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please check the selection"
            },
            options: [
              {
                component: "option",
                label: "Please select an option",
                value: ""
              },
              {
                component: "option",
                label: "On-premise",
                value: "onPremise"
              },
              {
                component: "option",
                label: "On-cloud",
                value: "onCloud"
              },
              {
                component: "option",
                label: "Both on-cloud & on-premise",
                value: "bothCloudPremise"
              }
            ]
          },
          {
            component: "dropdowns",
            label: "Do you outsource any part of your services?",
            type: "text",
            _uid: "common.isServiceOutsourced",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please check the selection"
            },
            options: [
              {
                component: "option",
                label: "Please select an option",
                value: ""
              },
              {
                component: "option",
                label: "Yes",
                value: "Y"
              },
              {
                component: "option",
                label: "No",
                value: "N"
              }
            ]
          }
        ]
      },
      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  },
  //Conditional -  IT infrastructure questions
  {
    component: "page",
    label: "Tell us about your IT infrastructure",
    _uid: "infraIT.questions",
    conditional: {
      value: "Y",
      label: "Tell us about your IT infrastructure~services.training",
      field: "services.audit$services.advisory$services.certification$complianceRequirement.iso27001$complianceRequirement.iso22301$complianceRequirement.pcidss$complianceRequirement.soc2$complianceRequirement.iso27701$complianceRequirement.hippa$complianceRequirement.csma$complianceRequirement.iso20000$complianceRequirement.cisa$complianceRequirement.nsar~services.training"
    },
    fields: [
      {
        colSize: "col-lg-8",
        component: "field_group",
        label: "",
        _uid: "eb169f76-4cd9-4513-b673-87c5c7d27e02",
        fields: [
          {

            component: "dropdowns",
            label: "Number of Servers",
            type: "radio",
            _uid: "infraIT.numOfServers",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please check the selection"
            },
            options: [
              {
                component: "option",
                label: "Please select an option",
                value: ""
              },
              {
                component: "option",
                label: "1-10",
                value: "1-10"
              },
              {
                component: "option",
                label: "11-50",
                value: "11-50"
              },
              {
                component: "option",
                label: "51-100",
                value: "51-100"
              },
              {
                component: "option",
                label: "101-1000",
                value: "101-1000"
              },
              {
                component: "option",
                label: "1001-10000",
                value: "1001-10000"
              },
              {
                component: "option",
                label: "10000+",
                label: "10000+",
              }
            ]
          },
          {

            component: "dropdowns",
            label: "Number of routers, switches, & firewall",
            type: "radio",
            _uid: "infraIT.numOfRoutersSwitchesFirewalls",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please check the selection"
            },
            options: [
              {
                component: "option",
                label: "Please select an option",
                value: ""
              },
              {
                component: "option",
                label: "1-5",
                value: "1-5"
              },
              {
                component: "option",
                label: "6-10",
                value: "6-10"
              },
              {
                component: "option",
                label: "11-20",
                value: "11-20"
              },
              {
                component: "option",
                label: "21-50",
                value: "21-50"
              },
              {
                component: "option",
                label: "51-100",
                value: "51-100"
              },
              {
                component: "option",
                label: "101-1000",
                value: "101-1000"
              },
              {
                component: "option",
                label: "1001-10000",
                value: "1001-10000"
              },
              {
                component: "option",
                label: "10000+",
                label: "10000+",
              }
            ]
          },
          {

            component: "textArea",
            label: "Details of application(s) used",
            type: "textArea",
            _uid: "infraIT.applicationsUsed",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please fill out the application(s) used by your organization"
            }
          },
          {
            component: "textArea",
            label: "Details of network segmentation, if any",
            type: "textArea",
            _uid: "infraIT.networkSegmentationDetails",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please fill out the application(s) used by your organization"
            }
          },
          {
            component: "text",
            label: "SEIM Used",
            type: "textArea",
            _uid: "infraIT.SEIM",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please fill out the SEIM used by your organization"
            }
          },
          {
            component: "text",
            label: "IDPS Implemented",
            type: "textArea",
            _uid: "infraIT.IDPS",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please fill out the IDPS implemented by your organization"
            }
          },
          {
            component: "dropdowns",
            label: "Cloud infrastructure details",
            type: "text",
            _uid: "common.cloudInfraDetails",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please check the selection"
            },
            options: [
              {
                component: "option",
                label: "Please select an option",
                value: ""
              },
              {
                component: "option",
                label: "Amazon Web Services",
                value: "amazonWebServices"
              },
              {
                component: "option",
                label: "Microsoft Azure",
                value: "microsoftAzure"
              },
              {
                component: "option",
                label: "Google Cloud Platform ",
                value: "googleCloudPlatform "
              },
              {
                component: "option",
                label: "Alibaba Cloud ",
                value: "alibabaCloud "
              },
              {
                component: "option",
                label: "IBM Cloud ",
                value: "ibmCloud "
              },
              {
                component: "option",
                label: "Digital Ocean",
                value: "digitalOcean"
              },
              {
                component: "option",
                label: "None",
                value: "none"
              },
              {
                component: "option",
                label: "Other",
                value: "other"
              }
            ]
          },
          {
            component: "dropdowns",
            label: "Log management details",
            type: "text",
            _uid: "common.logManagementDetails",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please check the selection"
            },
            options: [
              {
                component: "option",
                label: "Please select an option",
                value: ""
              },
              {
                component: "option",
                label: "SolarWinds Log Analyzer",
                value: "solarWindsLogAnalyzer"
              },
              {
                component: "option",
                label: "Sematext Logs",
                value: "sematextLogs"
              },
              {
                component: "option",
                label: "Datadog",
                value: "datadog"
              },
              {
                component: "option",
                label: "Splunk",
                value: "splunk"
              },
              {
                component: "option",
                label: "ManageEngine EventLog Analyzer",
                value: "manageEngineEventLogAnalyzer"
              },
              {
                component: "option",
                label: "LogDNA",
                value: "logDNA"
              },
              {
                component: "option",
                label: "Fluentd",
                value: "fluentd"
              },
              {
                component: "option",
                label: "Logalyze",
                value: "logalyze"
              },
              {
                component: "option",
                label: "Graylog",
                value: "graylog"
              },
              {
                component: "option",
                label: "None",
                value: "none"
              },
              {
                component: "option",
                label: "Other",
                value: "other"
              }
            ]
          },
          {
            component: "dropdowns",
            label: "Authentication mechanism details",
            type: "text",
            _uid: "common.authMechanismDetails",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please check the selection"
            },
            options: [
              {
                component: "option",
                label: "Please select an option",
                value: ""
              },
              {
                component: "option",
                label: "Duo Access",
                value: "duoAccess"
              },
              {
                component: "option",
                label: "Duo MFA",
                value: "duoMFA"
              },
              {
                component: "option",
                label: "Okta",
                value: "okta"
              },
              {
                component: "option",
                label: "Microsoft Multi-Factor Authentication",
                value: "microsoftMultiFactorAuthentication"
              },
              {
                component: "option",
                label: "Imprivata OneSign",
                value: "imprivataOneSign"
              },
              {
                component: "option",
                label: "SecureAuth Identity Platform",
                value: "secureAuthIdentityPlatform"
              },
              {
                component: "option",
                label: "CrowdStrike Falcon Identity Protection",
                value: "crowdStrikefFalconIdentityProtection"
              },
              {
                component: "option",
                label: "None",
                value: "none"
              },
              {
                component: "option",
                label: "Other",
                value: "other"
              }
            ]
          },
          {
            component: "dropdowns",
            label: "Encryption deployed ",
            type: "text",
            _uid: "common.encryptionDeployedDetails",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please check the selection"
            },
            options: [
              {
                component: "option",
                label: "Please select an option",
                value: ""
              },
              {
                component: "option",
                label: "Yes",
                value: "Y"
              },
              {
                component: "option",
                label: "No",
                value: "N"
              },
              {
                component: "option",
                label: "None",
                value: "none"
              },
              {
                component: "option",
                label: "Other",
                value: "other"
              }
            ]
          }
        ]
      },
      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  },
  //Conditional - PCSDSS
  {
    component: "page",
    label: "PCI DSS",
    _uid: "cd392929-c62e-4cdb-b4dd-914035c1cc11",
    conditional: {
      value: "Y",
      field:
        "page_complianceRequirement.pcidss~services.training"
    },
    fields: [
      {
        colSize: "col-lg-8",
        component: "dropdowns",
        label: "Do you store/process/ transmit card information",
        type: "text",
        _uid: "pcidss.cardStorageDetails",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please check the selection"
        },
        options: [
          {
            component: "option",
            label: "Please select an option",
            value: ""
          },
          {
            component: "option",
            label: "Yes",
            value: "Y"
          },
          {
            component: "option",
            label: "No",
            value: "N"
          },
          {
            component: "option",
            label: "Other",
            value: "other"
          }
        ]
      },
      {
        colSize: "col-lg-8",
        component: "dropdowns",
        label: "Have you been assessed previously? Do you have the ROC/AOC?",
        type: "text",
        _uid: "pcidss.previousAssessmentDetails",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please check the selection"
        },
        options: [
          {
            component: "option",
            label: "Please select an option",
            value: ""
          },
          {
            component: "option",
            label: "Yes",
            value: "Y"
          },
          {
            component: "option",
            label: "No",
            value: "N"
          },
          {
            component: "option",
            label: "Other",
            value: "other"
          }
        ]
      },
      {
        colSize: "col-lg-8",
        component: "dropdowns",
        label: "Do you need ASV scans / VAPT support?",
        type: "text",
        _uid: "pcidss.ASVAPTDetails",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please check the selection"
        },
        options: [
          {
            component: "option",
            label: "Please select an option",
            value: ""
          },
          {
            component: "option",
            label: "Yes",
            value: "Y"
          },
          {
            component: "option",
            label: "No",
            value: "N"
          },
          {
            component: "option",
            label: "Other",
            value: "other"
          }
        ]
      },
      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  },
  //Conditional - HIPPA
  {
    component: "page",
    label: "HIPPA",
    _uid: "hippa-c62e-4cdb-b4dd-914035c1cc12",
    conditional: {
      value: "Y",
      field:
        "page_complianceRequirement.hippa~services.training"
    },
    fields: [
      {
        colSize: "col-lg-8",
        component: "dropdowns",
        label: "Do you store/ process/ transmit PHI data?",
        type: "text",
        _uid: "hippa.storeTransmitPHI",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please check the selection"
        },
        options: [
          {
            component: "option",
            label: "Please select an option",
            value: ""
          },
          {
            component: "option",
            label: "Yes",
            value: "Y"
          },
          {
            component: "option",
            label: "No",
            value: "N"
          },
          {
            component: "option",
            label: "Other",
            value: "other"
          }
        ]
      },
      {
        colSize: "col-lg-8",
        component: "text",
        label: "For whom do you need the HIPAA report?",
        type: "textArea",
        _uid: "hippa.hippaReportSource",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please fill out from whom do you need the report from"
        }
      },
      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  },
  //Conditional - PRIVACY
  {
    component: "page",
    label: "Privacy",
    _uid: "privacy-c62e-4cdb-b4dd-914035c1cc12",
    conditional: {
      value: "Y",
      field:
        "page_complianceRequirement.privacyGDPRiso27701~services.training"
    },
    fields: [
      {
        colSize: "col-lg-8",
        component: "textArea",
        label: "What kind of PII/ Privacy data do you manage/process?",
        type: "textArea",
        _uid: "privacy.kindaOfData",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please fill out kind of PII/ Privacy data do you manage/process"
        }
      },
      {
        colSize: "col-lg-8",
        component: "dropdowns",
        label: "Have you conducted Privacy Impact Assessment (PIA)?",
        type: "text",
        _uid: "privacy.isPIACompleted",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please check the selection"
        },
        options: [
          {
            component: "option",
            label: "Please select an option",
            value: ""
          },
          {
            component: "option",
            label: "Yes",
            value: "Y"
          },
          {
            component: "option",
            label: "No",
            value: "N"
          }
        ]
      },
      {
        colSize: "col-lg-8",
        component: "dropdowns",
        label: "Have you conducted Privacy Impact Assessment (PIA)?",
        type: "text",
        _uid: "privacy.anySpecificLegalRequirements",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please check the selection"
        },
        options: [
          {
            component: "option",
            label: "Please select an option",
            value: ""
          },
          {
            component: "option",
            label: "Yes",
            value: "Y"
          },
          {
            component: "option",
            label: "No",
            value: "N"
          }
        ]
      },
      {
        conditional: {
          value: "Y",
          field:
            "privacy.isPIACompleted"
        },
        colSize: "col-lg-8",
        component: "text",
        label: "When was PIA last conducted?",
        type: "text",
        _uid: "privacy.PIALastConductedOn",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please fill out when was PIA last conducted"
        }
      },
      {
        conditional: {
          value: "Y",
          field:
            "privacy.anySpecificLegalRequirements"
        },
        colSize: "col-lg-8",
        component: "textArea",
        label: "What is the specific legal requirements applicable?",
        type: "text",
        _uid: "privacy.specificLegalRequirements",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please fill out when was PIA last conducted"
        }
      },
      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  },
  //Conditional - APPSEC
  {
    component: "page",
    label: "Application Security",
    _uid: "appsec-c62e-4cdb-b4dd-914035c1cc12",
    conditional: {
      value: "Y",
      field:
        "page_complianceRequirement.appSecAssessment~services.training"
    },
    fields: [
      {
        colSize: "col-lg-8",
        component: "field_group",
        hideTitle: false,
        label: "Please choose the architecture details of application",
        _uid: "appsec.ApplicationDetails",
        fields: [
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Stand alone",
            type: "checkbox",
            _uid: "appsec.standalone",
            value: "Y",
          },
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Client-Server",
            type: "checkbox",
            _uid: "appsec.clientServer",
            value: "Y",
          },
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Web Application",
            type: "checkbox",
            _uid: "appsec.webApplication",
            value: "Y",
          }

        ]
      },
      {
        colSize: "col-lg-8",
        component: "field_group",
        hideTitle: false,
        label: "Type of Testing required",
        _uid: "appsec.testingTypeRequired",
        fields: [
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Black box (No source code provided)",
            type: "checkbox",
            _uid: "appsec.blackBox",
            value: "Y",
          },
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "White box (Source code provided)",
            type: "checkbox",
            _uid: "appsec.whiteBox",
            value: "Y",
          },
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Grey box (Limited information provided)",
            type: "checkbox",
            _uid: "appsec.greyBox",
            value: "Y",
          }

        ]
      },
      {
        colSize: "col-lg-8",
        component: "textArea",
        label: "Please provide details of operational platform/architecture/ programming language etc.",
        type: "text",
        _uid: "appsec.opsArchPgmLanguageDetails",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please fill out operational platform/architecture/programming language details"
        }
      },
      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  },
  //Conditional - SOC
  {
    component: "page",
    label: "SOC",
    _uid: "soc-c62e-4cdb-b4dd-914035c1cc12",
    conditional: {
      value: "Y",
      field:
        "page_complianceRequirement.soc2~services.training"
    },
    fields: [
      {
        colSize: "col-lg-8",
        component: "field_group",
        hideTitle: false,
        label: "What SOC service are you looking for?",
        _uid: "soc.serviceType",
        fields: [
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "SOC-1",
            type: "checkbox",
            _uid: "soc.serviceType.soc1",
            value: "Y",
          },
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "SOC-2",
            type: "checkbox",
            _uid: "soc.serviceType.soc2",
            value: "Y",
          },
        ]
      },
      {
        colSize: "col-lg-8",
        component: "field_group",
        hideTitle: false,
        label: "Are you looking for these attestations?",
        _uid: "cb2dbfb0-a51b-4f64-82e6-a6a3369efc02",
        fields: [
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Type-1",
            type: "checkbox",
            _uid: "4350bb53-30d6-4427-9964-0f349f89627a",
            value: "Y",
          },
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Type-2",
            type: "checkbox",
            _uid: "c69f5589-77e6-413a-98be-9a1703a93d89",
            value: "Y",
          },
        ]
      },
      {
        colSize: "col-lg-8",
        component: "field_group",
        hideTitle: false,
        label: "Principles required?",
        _uid: "b73fb89f-b292-48e8-9c02-c4dcde70b2ba",
        fields: [
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Privacy",
            type: "checkbox",
            _uid: "soc.principlesRequired.privacy",
            value: "Y",
          },
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Security",
            type: "checkbox",
            _uid: "soc.principlesRequired.security",
            value: "Y",
          },
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Confidentiality",
            type: "checkbox",
            _uid: "soc.principlesRequired.confidentiality",
            value: "Y",
          },
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Availability",
            type: "checkbox",
            _uid: "soc.principlesRequired.availability",
            value: "Y",
          },
          {
            // colSize: "col-lg-6",
            component: "checkbox",
            label: "Processing integrity",
            type: "checkbox",
            _uid: "soc.principlesRequired.processingIntegrity",
            value: "Y",
          },
        ]
      },
      {
        colSize: "col-lg-8",
        component: "field_group",
        hideTitle: false,
        label: "Remediation support & sub-service vendor",
        _uid: "6aa5a78e-5eb4-4b42-8d85-0d94c52bw4a9",
        fields: [
          {

            component: "dropdowns",
            label: "Are you also looking for remediation support?",
            type: "text",
            _uid: "soc.remediationSupport",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please check the selection"
            },
            options: [
              {
                component: "option",
                label: "Please select an option",
                value: ""
              },
              {
                component: "option",
                label: "Yes",
                value: "Y"
              },
              {
                component: "option",
                label: "No",
                value: "N",
              }
            ]
          },
          {
            component: "dropdowns",
            label: "Do you use any sub-service vendor?",
            type: "text",
            _uid: "soc.subServiceVendorUsed",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please check the selection"
            },
            options: [
              {
                component: "option",
                label: "Please select an option",
                value: ""
              },
              {
                component: "option",
                label: "Yes",
                value: "Y"
              },
              {
                component: "option",
                label: "No",
                value: "N",
              }
            ]
          }
        ]
      },
      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  },
  //Conditional - SMS
  {
    component: "page",
    label: "Service Management",
    _uid: "d1216ba1-4523-41b9-99d0-c8a1e5571978",
    conditional: {
      value: "Y",
      field:
        "page_complianceRequirement.iso20000~services.training"
    },
    fields: [
      {
        colSize: "col-lg-8",
        component: "field_group",
        hideTitle: false,
        label: "",
        _uid: "4580dcec-20c8-4368-b625-2ef0d8a3270d",
        fields: [
          {
            colSize: "col-lg-12",
            component: "dropdowns",
            label: "Are you using a service management tool?",
            type: "text",
            _uid: "sms.isSMSToolUsed",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please check the selection"
            },
            options: [
              {
                component: "option",
                label: "Please select an option",
                value: ""
              },
              {
                component: "option",
                label: "Yes",
                value: "Y"
              },
              {
                component: "option",
                label: "No",
                value: "N",
              }
            ]
          },
        ]
      },
      {
        colSize: "col-lg-8",
        component: "field_group",
        hideTitle: false,
        label: "Are these management systems supported by your current tool(s)?",
        colSize: "col-lg-8",
        _uid: "280993a1-0b7f-4975-a1b9-95ac08df7ef7",
        fields: [
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Incident management",
            type: "checkbox",
            _uid: "sms.isSupported.incidentMgmt",
            value: "Y",
          },
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Problem management",
            type: "checkbox",
            _uid: "sms.isSupported.problemMgmt",
            value: "Y",
          },
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Change management",
            type: "checkbox",
            _uid: "sms.isSupported.changeMgmt",
            value: "Y",
          },
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Configuration management",
            type: "checkbox",
            _uid: "sms.isSupported.configMgmt",
            value: "Y",
          },
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Availability management",
            type: "checkbox",
            _uid: "sms.isSupported.availabilityMgmt",
            value: "Y",
          },
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Capacity management",
            type: "checkbox",
            _uid: "sms.isSupported.capacityMgmt",
            value: "Y",
          },
        ]
      },
      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  },
  //Conditional - Audit as-a-service
  {
    component: "page",
    label: "Audit as-a-service",
    _uid: "2128e920-e801-47a5-85e5-3f10d84c281c",
    conditional: {
      value: "Y",
      field:
        "page_services.aaas~services.training"
    },
    fields: [

      {
        colSize: "col-lg-8",
        component: "field_group",
        label: "",
        _uid: "eb169f76-4cd9-4513-b673-87c5c7d27e02",
        fields: [
          {

            component: "text",
            label: "Number of audit resources you are looking for?",
            type: "text",
            _uid: "services.aaas.resourcesRequired",
            validationParams: {
              req: false,
              regexp: "[0-9]{1,2}",
              errMsg: "Please enter number of resources required"
            }
          },
          {
            component: "dropdowns",
            label: "Audit tool",
            type: "radio",
            _uid: "services.aaas.auditTool",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please check the selection"
            },
            options: [
              {
                component: "option",
                label: "Please select an option",
                value: ""
              },
              {
                component: "option",
                label: "Yes",
                value: "Y"
              },
              {
                component: "option",
                label: "No",
                value: "N"
              },

            ]
          },
          {
            component: "dropdowns",
            label: "Auditor qualification",
            type: "radio",
            _uid: "services.aaas.auditorQualification",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please check the selection"
            },
            options: [
              {
                component: "option",
                label: "Please select an option",
                value: ""
              },
              {
                component: "option",
                label: "Yes",
                value: "Y"
              },
              {
                component: "option",
                label: "No",
                value: "N"
              },

            ]
          },
          {
            component: "dropdowns",
            label: "At what frequency are you looking for a status review?",
            type: "radio",
            _uid: "services.aaas.statusReviewFrequency",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please check the selection"
            },
            options: [
              {
                component: "option",
                label: "Please select an option",
                value: ""
              },
              {
                component: "option",
                label: "Weekly",
                value: "weekly"
              },
              {
                component: "option",
                label: "Bi-Weekly",
                value: "bi-weekly"
              },
              {
                component: "option",
                label: "Monthly",
                value: "monthly"
              },
              {
                component: "option",
                label: "Quarterly",
                value: "quarterly"
              },
              {
                component: "option",
                label: "Other",
                value: "other"
              },
            ]
          },
          {
            colSize: "col-lg-12",
            component: "text",
            label: "Expected per/hour billing rate in USD?",
            type: "text",
            _uid: "services.aaas.expectedPerHourBillingRate",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please enter a valid entry"
            }
          },

        ]
      },

      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  },
  //Conditional - Advisory/Support
  {
    component: "page",
    label: "Advisory/Support",
    _uid: "d823c35a-776a-4ac9-b82e-1361da873a99",
    conditional: {
      value: "Y",
      field:
        "page_services.advisory~services.training"
    },
    fields: [
      {
        colSize: "col-lg-8",
        component: "text",
        label: "Expected time line for completing the project?",
        type: "text",
        _uid: "services.advisory.expectedTimeline",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please enter a valid expected completion date"
        }
      },
      {
        colSize: "col-lg-8",
        component: "textArea",
        label: "Brief description on the current state of the system?",
        type: "text",
        _uid: "services.advisory.descriptionOdCurrentSystem",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please fill out the details",
        }
      },
      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  },
  //Conditional - VAPT
  {
    component: "page",
    label: "VAPT",
    _uid: "2edc44f2-881d-4fcf-84ae-7c1d63195ca5",

    conditional: {
      value: "Y",
      field:
        "page_complianceRequirement.vapt~services.training"
    },
    fields: [
      {
        colSize: "col-lg-8",
        component: "field_group",
        hideTitle: false,
        label: "",
        _uid: "a97cbfc2-6e27-4eeb-ac18-175c1c45189d",
        fields: [
          {
            component: "text",
            label: "Number of internal IPs to be tested?",
            type: "text",
            _uid: "complianceRequirement.vapt.noOfInternalIPsToTest",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please enter the number of internal IPs to be tested"
            }
          },
          {
            component: "text",
            label: "Number of external IPs to be tested?",
            type: "text",
            _uid: "complianceRequirement.vapt.noOfExternalIPsToTest",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please enter the number of external IPs to be tested"
            }
          }
        ]
      },
      {
        colSize: "col-lg-8",
        component: "field_group",
        hideTitle: false,
        label: "",
        _uid: "f05b2248-7a3d-4136-8429-4b8835e8b452",
        fields: [
          {
            colSize: "col-lg-12",
            component: "dropdowns",
            label: "Do you need retesting?",
            type: "text",
            _uid: "complianceRequirement.vapt.doYouNeedRetesting",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please check the selection"
            },
            options: [
              {
                component: "option",
                label: "Please select an option",
                value: ""
              },
              {
                component: "option",
                label: "Yes",
                value: "Y"
              },
              {
                component: "option",
                label: "No",
                value: "N",
              }
            ]
          },
          {

            component: "textArea",
            label: "Do you need retesting post fixing of issues?",
            type: "text",
            _uid: "complianceRequirement.vapt.anySpecificTestingRequirements",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please fill out the details about ",
            }
          },
          {

            component: "textArea",
            label: "Please provide additional testing scope/infrastructure details?",
            type: "text",
            _uid: "complianceRequirement.vapt.additionalTestingScope",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please fill out the details about ",
            }
          }
        ]
      },
      {
        colSize: "col-lg-8",
        component: "field_group",
        hideTitle: false,
        label: "",
        _uid: "f05b2248-7a3d-4136-8429-4b8835e8s52",
        fields: [
          {
            colSize: "col-lg-12",
            component: "dropdowns",
            label: "Is the testing to be conducted on-site or off-site?",
            type: "text",
            _uid: "complianceRequirement.vapt.isThereTestingOnsiteOrOffsite",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please check the selection"
            },
            options: [
              {
                component: "option",
                label: "Please select an option",
                value: ""
              },
              {
                component: "option",
                label: "On-site",
                value: "on-site"
              },
              {
                component: "option",
                label: "Off-site",
                value: "off-site",
              }
            ]
          },

        ]
      },
      {
        conditional: {
          value: "on-site",
          field:
            "field_complianceRequirement.vapt.isThereTestingOnsiteOrOffsite"
        },
        colSize: "col-lg-8",
        component: "textArea",
        label: "Please specify all on-site locations where testing is required",
        type: "text",
        _uid: "complianceRequirement.vapt.onsiteLocationDetails",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please fill out the details about ",
        }
      },
      {
        colSize: "col-lg-8",
        component: "field_group",
        hideTitle: false,
        label: "",
        _uid: "f05b2248-7a3d-4136-8429-4b88l35e8ss2",
        fields: [
          {
            colSize: "col-lg-12",
            component: "dropdowns",
            label: "Will the systems be in production at the time of testing?",
            type: "text",
            _uid: "complianceRequirement.vapt.willThereBeSystemsInProduction",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please check the selection"
            },
            options: [
              {
                component: "option",
                label: "Please select an option",
                value: ""
              },
              {
                component: "option",
                label: "Yes",
                value: "Y"
              },
              {
                component: "option",
                label: "No",
                value: "N",
              }
            ]
          },

        ]
      },
      {
        colSize: "col-lg-8",
        component: "field_group",
        hideTitle: false,
        label: "",
        _uid: "f05b2248-7a3d-4136-8o29-4g8835e8ss2",
        fields: [
          {
            colSize: "col-lg-12",
            component: "dropdowns",
            label: "Is testing to be conducted during business hours or non-business hours?",
            type: "text",
            _uid: "complianceRequirement.vapt.testingConductedDuringBusinessHours",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please check the selection"
            },
            options: [
              {
                component: "option",
                label: "Please select an option",
                value: ""
              },
              {
                component: "option",
                label: "Business hours",
                value: "businessHours"
              },
              {
                component: "option",
                label: "Non-business hours",
                value: "nonBusinessHours",
              }
            ]
          },

        ]
      },
      {
        colSize: "col-lg-8",
        component: "field_group",
        hideTitle: false,
        label: "",
        _uid: "f05b2248-7a3d-4136-8429-4g8835e8ss2",
        fields: [
          {
            colSize: "col-lg-12",
            component: "dropdowns",
            label: "Are Denial of Service(DoS) testing required?",
            type: "text",
            _uid: "complianceRequirement.vapt.dosTestingRequired",
            validationParams: {
              req: false,
              regexp: "",
              errMsg: "Please check the selection"
            },
            options: [
              {
                component: "option",
                label: "Please select an option",
                value: ""
              },
              {
                component: "option",
                label: "Yes",
                value: "Y"
              },
              {
                component: "option",
                label: "No",
                value: "N",
              }
            ]
          },

        ]
      },
      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  },
  //Conditional - appSecAssessment
  {
    component: "page",
    label: "Some info on your Application",
    _uid: "2edc44f2-881d-4fcf-84ae-7c1d63195ca5",

    conditional: {
      value: "Y",
      field:
        "page_complianceRequirement.appSecAssessment~services.training"
    },
    fields: [
      {
        colSize: "col-lg-8",
        component: "field_group",
        hideTitle: false,
        label: "Please choose the architecture details of application",
        _uid: "appsec.ApplicationDetails",
        fields: [
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Stand alone",
            type: "checkbox",
            _uid: "appsec.standalone",
            value: "Y",
          },
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Client-Server",
            type: "checkbox",
            _uid: "appsec.clientServer",
            value: "Y",
          },
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Web Application",
            type: "checkbox",
            _uid: "appsec.webApplication",
            value: "Y",
          }

        ]
      },
      {
        colSize: "col-lg-8",
        component: "field_group",
        hideTitle: false,
        label: "Type of Testing required",
        _uid: "appsec.testingTypeRequired",
        fields: [
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Black box (No source code provided)",
            type: "checkbox",
            _uid: "appsec.blackBox",
            value: "Y",
          },
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "White box (Source code provided)",
            type: "checkbox",
            _uid: "appsec.whiteBox",
            value: "Y",
          },
          {
            //colSize: "col-lg-6",
            component: "checkbox",
            label: "Grey box (Limited information provided)",
            type: "checkbox",
            _uid: "appsec.greyBox",
            value: "Y",
          }

        ]
      },
      {
        colSize: "col-lg-8",
        component: "textArea",
        label: "Please provide details of operational platform/architecture/ programming language etc.",
        type: "text",
        _uid: "appsec.opsArchPgmLanguageDetails",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please fill out operational platform/architecture/programming language details"
        }
      },
      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  },
  // Conditional - Training
  {
    component: "page",
    label: "Training requirements",
    _uid: "fe49fd93-6a79-40a9-9369-0c7362360a47",
    conditional: {
      value: "Y",
      field:
        "UUID_services.training"
    },
    fields: [
      {
        colSize: "col-lg-8",
        component: "text",
        name: "Resource count",
        label: "Number of resources to be trained?",
        type: "text",
        _uid: "services.training.noOfResources",
        validationParams: {
          req: true,
          regexp: "",
          errMsg: "Please enter the number of resources"
        }
      },
      {
        colSize: "col-lg-8",
        component: "dropdowns",
        label: "Type of training?",
        type: "text",
        _uid: "services.training.typeOfTraining",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please check the selection"
        },
        options: [
          {
            component: "option",
            label: "Please select an option",
            value: ""
          },
          {
            component: "option",
            label: "Auditor training",
            value: "auditor"
          },
          {
            component: "option",
            label: "Implementation training",
            value: "implementation"
          },
          {
            component: "option",
            label: "Awareness/foundation training",
            value: "awarenessFoundation",
          }
        ]
      },
      {
        colSize: "col-lg-8",
        component: "dropdowns",
        label: "Is the training to be conducted on-site or off-site?",
        type: "text",
        _uid: "services.training.isThereTestingOnsiteOrOffsite",
        validationParams: {
          req: false,
          regexp: "",
          errMsg: "Please check the selection"
        },
        options: [
          {
            component: "option",
            label: "Please select an option",
            value: ""
          },
          {
            component: "option",
            label: "On-site",
            value: "on-site"
          },
          {
            component: "option",
            label: "Off-site",
            value: "off-site",
          }
        ]
      },
      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  },
  //Message
  {
    component: "page",
    label: "Would you like to add any message for us?",
    _uid: "0c946643-5a83-4545-baea-065b27b51e8a",
    fields: [
      {
        colSize: "col-lg-8",
        component: "textArea",
        label: "Message",
        type: "text",
        _uid: "lastpage.message"
      },
      {
        colSize: "col-lg-8",
        component: "reCaptcha",
        type: "reCaptcha",
        _uid: "lastpage.reCaptcha"
      },
      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  },
  //Final - thank you
  {
    component: "page",
    label: "",
    _uid: "endTitle",
    fields: [
      {
        component: "titleCard",
        title: "Thank you.",
        subTitle: "We've received the information you've filled. One of our team members would reach out to you.",
        hideButton: true,
        type: "text",
        _uid: "f61231e8-565e-43d0-9c14-7d7f220c6020",
        animation: <Lottie style={{ height: '15em', textAlign: 'center' }} loop={true} animationData={yellowCheck} />
      },
      {
        component: "pageNav",
        _uid: uuid()
      }
    ]
  }
];
