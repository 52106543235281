let firebaseConfig = {
    apiKey: "AIzaSyCvHrt3KnRIQpwpZwANaTE1b532J6DO6UU",
    authDomain: "certia-b982f.firebaseapp.com",
    databaseURL: "https://certia-b982f-default-rtdb.firebaseio.com",
    projectId: "certia-b982f",
    storageBucket: "certia-b982f.appspot.com",
    messagingSenderId: "107681072080",
    appId: "1:107681072080:web:58893ab1a4f4822102afd5",
    measurementId: "G-BKCXEJRB0S"
};

export default firebaseConfig;