import React from "react";
import uuid from 'react-uuid'

import { Building, People, ClockHistory, Calendar3, ShieldLock, Lock, BookFill, Boxes, CardChecklist, CheckAll, Mortarboard, PersonCheck } from 'react-bootstrap-icons';

import ServiceSummary from '../../components/ServiceSummary'
import StatsCard from '../../components/StatsCard'
import ServiceButtons from '../../components/ServiceButtons'

import Capabilities from '../../components/svg/Capabilities'
import AuditProcessCarousel from '../../components/carousels/AuditProcessCarousel' 
import AuditProcessAccordion from '../../components/accordions/AuditProcessAccordion'
import CapabilitiesAccordion from '../../components/accordions/CapabilitiesAccordion'
import DesignAdvisoryAccordion from '../../components/accordions/DesignAdvisoryAccordion'

const services = [
    {
        title: "Audit & verification",
        description: "Our partner's experienced assessors can perform independent, detailed adequacy and compliance pre-certification gap-audit. We also support your internal audit process based on various global compliance criteria.",
        link: "audit",
        serviceSummaryArray: [
            ,
        ], statsArray: [
            <StatsCard key={uuid()} icon={<Building key={uuid()} class="bi fs-1" />} heading="20+" subtitle="service partners" />,
            <StatsCard key={uuid()} icon={<People key={uuid()} class="bi fs-1" />} heading="100+" subtitle="auditors globally" />,
            <StatsCard key={uuid()} icon={<PersonCheck key={uuid()} class="bi fs-1" />} heading="50+" subtitle="approved consultants" />,
            <StatsCard key={uuid()} icon={<Boxes key={uuid()} class="bi fs-1" />} heading="20+" subtitle="years experienced partners" />,
        ],
        serviceSubSummaryArray: [
            {
                subSummaryTitle: "What can we do for you?",
                subSummaries: [
                    <ServiceSummary key={uuid()} title="Audit as a service" description="We can provide you with approved auditors to perform audits covering various management systems, standards, & specific compliance requirements. We can act as your extended audit team, alleviating you from the hassle of developing, maintaining, & managing your own audit staff." />,
                    <ServiceSummary key={uuid()} title="Support your audit team" description="We can provide you with resources to help you with your internal audits.  We'll work with your team to take on your audit load if you ever need help with completing more audits." />,
                ],
                subSummaryStats: [
                    <StatsCard key={uuid()} icon={<People class="bi fs-1" />} heading="156" subtitle="QSAs available" />,
                    <StatsCard key={uuid()} icon={<Building class="bi fs-1" />} heading="324" subtitle="client engagements" />,
                    <StatsCard key={uuid()} icon={<ClockHistory class="bi fs-1" />} heading="7548" subtitle="man hours of audits" />,
                    <StatsCard key={uuid()} icon={<Lock class="bi fs-1" />} heading="16+" subtitle="years experience" />,]

            },
            {
                subSummaryTitle: "Our Capabilities",
                subSummaries: [
                    <ServiceSummary key={uuid()} title="Expertise to perform audits & verifications" description="Our partner's experienced assessors can perform independent and
                detailed adequacy and compliance audits process based on various global
                compliance criteria as listed below"
                        accordion={<CapabilitiesAccordion key={uuid()} />}
                    />,
                ],
                subSummaryStats: [
                    <StatsCard key={uuid()} icon={<People key={uuid()} class="bi fs-1" />} heading="156" subtitle="QSAs available" />,
                    <StatsCard key={uuid()} icon={<Building key={uuid()} class="bi fs-1" />} heading="324" subtitle="client engagements" />,
                    <StatsCard key={uuid()} icon={<ClockHistory key={uuid()} class="bi fs-1" />} heading="7548" subtitle="man hours of audits" />,
                    <StatsCard key={uuid()} icon={<Lock key={uuid()} class="bi fs-1" />} heading="16+" subtitle="years experience" />,]

            },
            {
                subSummaryTitle: "Our approach",
                subSummaries: [
                    <ServiceSummary key={uuid()} title="A methodical approach for a smooth and successful audit"
                        accordion={<AuditProcessCarousel key={uuid()} />}
                        description="" />
                ],
                subSummaryStats: [
                    <StatsCard  key={uuid()} icon={<People key={uuid()} class="bi fs-1" />} heading="156" subtitle="QSAs available" />,
                    <StatsCard  key={uuid()} icon={<Building key={uuid()} class="bi fs-1" />} heading="324" subtitle="client engagements" />,
                    <StatsCard  key={uuid()} icon={<ClockHistory key={uuid()} class="bi fs-1" />} heading="7548" subtitle="man hours of audits" />,
                    <StatsCard  key={uuid()} icon={<Lock key={uuid()} class="bi fs-1" />} heading="16+" subtitle="years experience" />,]

            },
            {
                subSummaryTitle: "Why hire us?",
                subSummaries: [
                    <ServiceSummary key={uuid()} title="We'll do all the heavy lifting" description="Count on our years of auditing experience whenever you work with us. We will handle the most demanding aspects of your audits with the utmost professionalism and care." />,
                    <ServiceSummary key={uuid()} title="Highly experienced partners" description="With our partners, we have access to 20+ years of global experience in standards and compliance." />,
                    <ServiceSummary key={uuid()} title="Lean ops to pass on the savings" description="With a lean organization, we optimize productivity and costs, which in turn allows us to provide high-quality work on an affordable budget." />,
                    <ServiceSummary key={uuid()} title="Flexible hiring" description="Scale resources up and down as your workload changes. We can act as your extended audit team, alleviating you from the hassle of developing, maintaining, & managing your own audit staff." />,
                    <ServiceSummary key={uuid()} title="Transparent communication" description="We always put your needs first when we work together, which is why we answer our phones during normal business hours and respond to emails the same day." />,
                ],
                subSummaryStats: [
                    <StatsCard key={uuid()} icon={<People key={uuid()} class="bi fs-1" />} heading="156" subtitle="QSAs available" />,
                    <StatsCard key={uuid()} icon={<Building key={uuid()} class="bi fs-1" />} heading="324" subtitle="client engagements" />,
                    <StatsCard key={uuid()} icon={<ClockHistory key={uuid()} class="bi fs-1" />} heading="7548" subtitle="man hours of audits" />,
                    <StatsCard key={uuid()} icon={<Lock key={uuid()} class="bi fs-1" />} heading="16+" subtitle="years experience" />,]

            }],
        serviceButtons: <ServiceButtons key={uuid()} />
    },
    {
        title: "Support & advisory",
        link: "support",
        description: "Our partner's advisors can help your organization design system components to improve your business processes.",

        serviceSubSummaryArray: [
            {
                subSummaryTitle: "What can we do for you?",
                subSummaries: [
                    <ServiceSummary key={uuid()} title="System design & advisory" description="We can provide you with advisors to perform system design in accordance with various standards and compliance requirements. We can serve as your extended team, relieving you of the burden of developing, maintaining, and managing your internal systems." />,
                    <ServiceSummary key={uuid()} title="Implementation support" description="We can also provide you with resources to help you with system updates, internal audits, reviews, monitoring, and measurement to ensure continued compliance. Our implementation team will handle your additional workload so you can concentrate on your business initiatives." />,
                ],
                subSummaryStats: [
                    <StatsCard key={uuid()} icon={<Building key={uuid()} class="bi fs-1" />} heading="20+" subtitle="service partners" />,
                    <StatsCard key={uuid()} icon={<People key={uuid()} class="bi fs-1" />} heading="100+" subtitle="auditors globally" />,
                    <StatsCard key={uuid()} icon={<ClockHistory key={uuid()} class="bi fs-1" />} heading="50+" subtitle="approved consultants" />,
                    <StatsCard key={uuid()} icon={<Boxes key={uuid()} class="bi fs-1" />} heading="20+" subtitle="years experienced partners" />,]

            },
            {
                subSummaryTitle: "Our Capabilities",
                subSummaries: [
                    <ServiceSummary key={uuid()} title="Expertise for system design & support" description="Expert advisors from our partners can assist you in designing a system that meets all standard requirements, contractual requirements, and all applicable legal and regulatory requirements. We will design a system that meets the needs of your business."
                        accordion={<CapabilitiesAccordion key={uuid()} />}
                    />,
                ],
                subSummaryStats: [
                    ,]

            },
            {
                subSummaryTitle: "Our approach",
                subSummaries: [
                    <ServiceSummary key={uuid()} title="A methodical approach for system design"
                        accordion={<DesignAdvisoryAccordion key={uuid()} />}
                        description="" />
                ],
                subSummaryStats: [
                ]

            },
            {
                subSummaryTitle: "Why hire us?",
                subSummaries: [
                    <ServiceSummary key={uuid()} title="We'll do all the heavy lifting" description="Count on our years of auditing experience whenever you work with us. We will handle the most demanding aspects of your audits with the utmost professionalism and care." />,
                    <ServiceSummary key={uuid()} title="Highly experienced partners" description="With our partners, we have access to 20+ years of global experience in standards and compliance." />,
                    <ServiceSummary key={uuid()} title="Lean ops to pass on the savings" description="With a lean organization, we optimize productivity and costs, which in turn allows us to provide high-quality work on an affordable budget." />,
                    <ServiceSummary key={uuid()} title="Flexible hiring" description="Scale resources up and down as your workload changes. We can act as your extended audit team, alleviating you from the hassle of developing, maintaining, & managing your own audit staff." />,
                    <ServiceSummary key={uuid()} title="Transparent communication" description="We always put your needs first when we work together, which is why we answer our phones during normal business hours and respond to emails the same day." />,
                ]
            }
        ],
        serviceSummaryArray: [

            ,
        ], statsArray: [
            <StatsCard key={uuid()} icon={<Building key={uuid()} class="bi fs-1" />} heading="10+" subtitle="service partners" />,
            <StatsCard key={uuid()} icon={<People key={uuid()} class="bi fs-1" />} heading="50+" subtitle="advisors globally" />,
            <StatsCard key={uuid()} icon={<CheckAll key={uuid()} class="bi fs-1" />} heading="100+" subtitle="partner projects completed" />,
            <StatsCard key={uuid()} icon={<Boxes key={uuid()} class="bi fs-1" />} heading="10+" subtitle="years experienced partners" />,
        ],
        serviceButtons: <ServiceButtons key={uuid()} />
    },
    {
        title: "Training",
        description: "Our partner's approved trainers can provide remote/ onsite training to develop your team's competency, covering various management systems/ standards.",
        link: "training",
        serviceSummaryArray: [
            ,
        ], statsArray: [
            <StatsCard key={uuid()} icon={<People key={uuid()} class="bi fs-1" />} heading="50+" subtitle="trainers available" />,
            <StatsCard key={uuid()} icon={<Calendar3 key={uuid()} class="bi fs-1" />} heading="1000+" subtitle="days of training" />,
            <StatsCard key={uuid()} icon={<Mortarboard key={uuid()} class="bi fs-1" />} heading="5000+" subtitle="resources trained globally" />,
            <StatsCard key={uuid()} icon={<Boxes key={uuid()} class="bi fs-1" />} heading="15+" subtitle="years experience" />,
        ],
        serviceSubSummaryArray: [{
            subSummaryTitle: "What we offer",
            subSummaries: [
                <ServiceSummary key={uuid()} title="We provide online/ 
                \
                in-house trainings on various compliance,
                standards and tools" description="List of the training offerings our partners provide: \
                \
                Security training \
                PCI training \
                HIPAA training \
                GDPR training \
                Continuity training \
                SOC training \
                Risk management training \
                IT service management training \
                Turn around management training \
                Balance score card training \
                Six sigma training \
                Food safety training \
                Lead implementers training \
                Internal auditors training" />,
            ],
            subSummaryStats: [
            ]

        },
        {
            subSummaryTitle: "Why hire us?",
            subSummaries: [

                <ServiceSummary key={uuid()} title="Highly experienced partners" description="With our partners, we have access to 20+ years of global experience in standards and compliance." />,
                <ServiceSummary key={uuid()} title="Lean ops to pass on the savings" description="With a lean organization, we optimize productivity and costs, which in turn allows us to provide high-quality work on an affordable budget." />,
                <ServiceSummary key={uuid()} title="Transparent communication" description="We always put your needs first when we work together, which is why we answer our phones during normal business hours and respond to emails the same day." />,
            ],
            subSummaryStats: [
            ]

        }],
        serviceButtons: <ServiceButtons key={uuid()} />
    }
];

export default services;

