import React from "react";
import { Building, People, ClockHistory, Calendar3, Lock, BookHalf } from 'react-bootstrap-icons';
import services from './Services-Data.js'
import about from './About-Data.js'
import Lottie from "lottie-react";
import yellowCheck from "../../resources/lotties/yellow_check-2.json";
const data =
{
    title: "Audit",
    companyShortName: "Certia",
    companyFullName: "Certia LLC.",
    domain: "certiallc.com",
    email: "contact@certiallc.com",
    tel: "+1 (214) 833-6025",
    address: {
        line1: "3839 McKinney Ave,",
        line2: "Suite 155-246,",
        city: "Dallas",
        state: "TX",
        zipCode: "75204"
    },
    pageData: {
        home: {
            location: "Dallas, TX",
            title: "Quick, thorough, & affordable *audits + advisory*.",
            subTitle: "We have teamed up with established firms that provide assessments, audits, advisory & verification in a wide range of industries.",
            buttonOne: "Get Started",
            buttonTwo: "Learn More"
        },
        services: services,
        about: about,
        discoveryCall: {
            animation: <Lottie style={{ height: '15em', textAlign: 'center' }} loop={true} animationData={yellowCheck} />,
            title: "Thank you.",
            subTitle: "We've received the meeting you've scheduled. Talk to you soon.",
            hideButton: true

        }
    }

}

export default data;

