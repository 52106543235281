import React from "react";
import uuid from 'react-uuid'

import Field from "./Field";
import Select from "./Select";
import Checkbox from "./Checkbox";
import TextArea from "./TextArea";
import AnimatedPages from "../pages/AnimatedPages";

const FieldGroup = ({ field, fieldChanged, values, fieldValidate }) => {
  const fields = field.fields;
  const fieldGrpId = field._uid;
  return (<AnimatedPages>
    <div  className={field.colSize != null && field.colSize ? field.colSize : "col-lg-12"}>
      <fieldset key={field._uid} id={field._uid}>
        <div hidden={field.hideTitle != null && !field.hideTitle ? field.hideTitle : true}><p className="text-white-50">{field.label}</p></div>
        <div className="row justify-content-left">
          {fields.map((field) => {
            switch (field.component) {
              case "dropdowns":
                return (
                  <div  className={field.colSize != null && field.colSize ? field.colSize : "col-lg-6"}>
                    <Select
                      key={field._uid}
                      field={field}
                      fieldChanged={fieldChanged}
                      value={values[field._uid]}
                      fieldValidate={fieldValidate}
                    />
                  </div>
                );
              case "checkbox":
                return (
                  <div  className={field.colSize != null && field.colSize ? field.colSize : "col-lg-6"}>
                    <Checkbox
                      key={field._uid}
                      field={field}
                      fieldChanged={fieldChanged}
                      value={values[field._uid]}
                      fieldValidate={fieldValidate}
                      fieldGrpId={fieldGrpId}
                    />
                  </div>
                );
              case "textArea":
                return (
                  < div className={field.colSize != null && field.colSize ? field.colSize : "col-lg-12"}>
                    <TextArea
                      key={field._uid}
                      field={field}
                      fieldChanged={fieldChanged}
                      value={values[field._uid]}
                      fieldValidate={fieldValidate}
                    />
                  </div>
                );
              default:
                return (
                  <div  className={field.colSize != null && field.colSize ? field.colSize : "col-lg-6"}>
                    <Field
                      key={field._uid}
                      field={field}
                      fieldChanged={fieldChanged}
                      fieldValidate={fieldValidate}
                      value={values[field._uid]}
                    />
                  </div>
                );
            }
          })}
        </div>
      </fieldset>

    </div>
  </AnimatedPages>
  );
};

export default FieldGroup;
